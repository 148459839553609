import apiService from "../api/apiService";
import { getCookie } from "./cookieUtils";

const fetchDataAndBuildJson = async (
  taskName,
  jsObjectPath,
  additionalParams = {},
  skipApiCall = false
) => {
  const { localState, updateLocalState, ...filteredParams } = additionalParams;

  // If skipApiCall is true, use the provided data directly
  if (skipApiCall && additionalParams.localStateData) {
    const module = await import(`../buildJson/${jsObjectPath}`);
    const buildJson = module.default; // Directly access the default export

    if (typeof buildJson !== "function") {
      throw new Error("buildJson is not a function");
    }

    const builtJson = await buildJson({
      localStateData: additionalParams.localStateData,
    });
    return builtJson;
  }

  const token = getCookie("token");
  if (!token) {
    throw new Error("No token found. Authorization required.");
  }

  const userId = getCookie("user_id");
  const payload = { user_id: userId, ...filteredParams };

  try {
    const response = await apiService.handleProtectedRoute(taskName, payload);

    if (response.error) {
      throw new Error(response.error);
    }

    // Dynamically import the JS object
    const module = await import(`../buildJson/${jsObjectPath}`);
    const buildJson = module.default; // Directly access the default export

    if (typeof buildJson !== "function") {
      throw new Error("buildJson is not a function");
    }

    const builtJson = await buildJson(response);

    // Update the local state with the fetched data for future use
    if (taskName === "fetchUserNameAndAvatar") {
      if (
        response.success &&
        (response?.user?.fullname || response?.user?.avatar)
      )
        updateLocalState("userNameAndAvatar", response);
    } else if (taskName === "fetchUserAvatar") {
      updateLocalState("userAvatar", response);
    }
    if (taskName === "fetchTeamNameAndAvatar") {
      updateLocalState("teamNameAndAvatar", response);
    }
    if (taskName === "fetchTeamProfile") {
      updateLocalState("teamSettingsData", response);
    }
    if (taskName === "fetchUserProfile") {
      updateLocalState("userProfileData", response);
    }

    return builtJson;
  } catch (error) {
    console.error("Error fetching and building JSON:", error);
    throw error;
  }
};

export default fetchDataAndBuildJson;

// Helper function to convert binary data to Base64
const arrayBufferToBase64 = (buffer) => {
  let binary = "";
  const bytes = new Uint8Array(buffer);
  const len = bytes.byteLength;
  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return window.btoa(binary);
};
