import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom"; // Import useLocation hook
import DetailsPanel from "./DetailsPanel";
import { useLocalState } from "../../contexts/LocalStateProvider";

const ProjectPage = () => {
  const [projectDetails, setProjectDetails] = useState({
    name: "Happy Electrons",
    initialPrompt: "Create a happy electronic music track",
    coverImage: "/design-2.jpg",
  });
  const [versionHistory, setVersionHistory] = useState([
    {
      version: "Breeze and Beach",
      timestamp: "[timestamp]",
      duration: "0:30",
      src: "/placeholder.mp3",
      coverImage: "/design.jpg",
    },
  ]);

  const { localState, updateLocalState } = useLocalState();
  const [isDetailsPanelOpen, setIsDetailsPanelOpen] = useState(
    localState.isDetailsPanelOpen || false
  );
  const [pageWidth, setPageWidth] = useState(window.innerWidth);
  const location = useLocation(); // Use useLocation to get current route info

  useEffect(() => {
    const handleResize = () => {
      setPageWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    setIsDetailsPanelOpen(localState.isDetailsPanelOpen);
  }, [localState.isDetailsPanelOpen]);

  useEffect(() => {
    // Close the details panel when the route changes
    setIsDetailsPanelOpen(false);
    updateLocalState("isDetailsPanelOpen", false);
  }, [location.pathname]); // Trigger on route change

  const handleUpdateDetails = (updatedDetails) => {
    setProjectDetails({
      ...projectDetails,
      name: updatedDetails.name,
      initialPrompt: updatedDetails.description,
      coverImage: updatedDetails.coverImage,
    });
  };

  const handleCloseDetailsPanel = () => {
    setIsDetailsPanelOpen(false);
    updateLocalState("isDetailsPanelOpen", false);
  };

  return (
    <div className={`flex ${isDetailsPanelOpen ? "w-1/3" : "w-0"}`}>
      {isDetailsPanelOpen && (
        <DetailsPanel
          className={`${
            pageWidth < 768
              ? "fixed top-0 left-0 right-0 bottom-0 z-30 w-full h-full bg-[#100f10] overflow-y-auto"
              : "sticky bg-[#100f10] border border-[#2e2e2d] ml-1 h-full w-full rounded-lg overflow-y-auto"
          }`}
          projectDetails={projectDetails}
          onClose={handleCloseDetailsPanel}
          onUpdateDetails={handleUpdateDetails}
        />
      )}
    </div>
  );
};

export default ProjectPage;
