import React, { useEffect, useState, useRef } from "react";
import WaveSurfer from "wavesurfer.js";

const WaveformPlayer = ({
  waveform,
  audioSrc,
  currentTime,
  duration,
  isPlaying,
  onSeek,
  isActive,
}) => {
  const waveformRef = useRef(null);
  const waveSurferRef = useRef(null);
  const [waveformData, setWaveformData] = useState(waveform);
  const [audioSource, setAudioSource] = useState(audioSrc);

  useEffect(() => {
    if (waveformData.length > 0 && waveformRef.current && audioSrc) {
      // Create a WaveSurfer instance and load the waveform data
      const ws = WaveSurfer.create({
        container: waveformRef.current,
        waveColor: "rgba(255, 255, 255, 0.15)",
        progressColor: "#ffff",
        cursorColor: "#ff0000",
        cursorWidth: 0,
        responsive: true,
        interact: false, // will not allow seeking on the waveform
        barWidth: 2, // Increase bar width (thicker lines)
        barGap: 2, // Increase the gap between bars
        height: 36,
        barRadius: 2,
        minPxPerSec: 2000, // Set height scale of the bars
      });

      // Load the waveform data
      ws.load(" ", waveformData, waveformData.length);
      ws.setVolume(0);
      waveSurferRef.current = ws;

      //   // Seek handling when user clicks on the waveform
      //   ws.on("seek", (progress) => {
      //     if (onSeek) {
      //       const newTime = progress * duration;
      //       onSeek(newTime);
      //     }
      //   });
    }

    return () => {
      if (waveSurferRef.current) {
        waveSurferRef.current.destroy(); // Clean up when unmounting
      }
    };
  }, [waveformData, audioSource, isActive]);

  // Sync waveform progress with the current time
  useEffect(() => {
    if (waveSurferRef.current && duration > 0 && currentTime >= 0.5) {
      const progress = Math.min(currentTime / duration, 1);
      waveSurferRef.current.seekTo(progress);
    } else if (!isActive && waveSurferRef.current) {
      waveSurferRef.current.seekTo(0); // Reset progress for inactive songs
    }
  }, [currentTime, duration, isActive]);

  // Handle play/pause synchronization
  //   useEffect(() => {
  //     if (waveSurferRef.current) {
  //       if (isPlaying) {
  //         waveSurferRef.current.play();
  //       } else {
  //         waveSurferRef.current.pause();
  //       }
  //     }
  //   }, [isPlaying]);

  return (
    <div style={{ width: "280px", height: "100%" }}>
      <div ref={waveformRef} style={{ width: "100%", height: "36px" }} />
    </div>
  );
};

export default WaveformPlayer;
