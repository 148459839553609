import React, { createContext, useContext, useState } from "react";

const LocalStateContext = createContext();

export const LocalStateProvider = ({ children }) => {
  const [localState, setLocalState] = useState({});

  const updateLocalState = (key, value) => {
    setLocalState((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  return (
    <LocalStateContext.Provider value={{ localState, updateLocalState }}>
      {children}
    </LocalStateContext.Provider>
  );
};

export const useLocalState = () => useContext(LocalStateContext);
