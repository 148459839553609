import React, { useEffect, useState } from "react";

const loadingMessages = [
  "🥁 Dusting off the drum kit",
  "🎸 Tuning the guitars",
  "😴 Waking up the bassist",
  "🚫 Shooing the roadies off the stage",
  "🎵 Teaching the woodwind section to exhale",
  // "🦇 Chasing the bats away from Ozzy’s green room",
  "💈 Adding styling gel to ZZ’s beard",
  "🎷 Keeping the Sax-A-Boom on standby",
  "💔 Bracing Taylor’s ex-boyfriends for new music",
];

const loadingIcons = [
  "/loading-icon-1.svg",
  "/loading-icon-2.svg",
  "/loading-icon-3.svg",
  "/loading-icon-4.svg",
];

const LoadingComponent = () => {
  const [currentMessage, setCurrentMessage] = useState("");
  const [messageIndex, setMessageIndex] = useState(0);
  const [loadingIconIndex, setLoadingIconIndex] = useState(0);
  const [percentage, setPercentage] = useState(0);
  const [shuffledMessages, setShuffledMessages] = useState([]);

  useEffect(() => {
    // Shuffle the loadingMessages array when the component mounts
    const shuffled = [...loadingMessages].sort(() => Math.random() - 0.5);
    setShuffledMessages(shuffled);
  }, []);

  useEffect(() => {
    const iconInterval = setInterval(() => {
      setLoadingIconIndex((prev) => (prev + 1) % loadingIcons.length);
    }, 5000);

    const percentageInterval = setInterval(() => {
      setPercentage((prev) => (prev + 1) % 101);
    }, 500);

    return () => {
      clearInterval(iconInterval);
      clearInterval(percentageInterval);
    };
  }, []);

  useEffect(() => {
    let isMounted = true;
    const letterInterval = setInterval(() => {
      if (isMounted && shuffledMessages.length > 0) {
        setCurrentMessage((prev) => {
          const currentSentence = shuffledMessages[messageIndex];
          const nextMessage = currentSentence.slice(0, prev.length + 1);

          if (nextMessage === currentSentence) {
            clearInterval(letterInterval);
            setTimeout(() => {
              if (isMounted) {
                setMessageIndex(
                  (prevIndex) => (prevIndex + 1) % shuffledMessages.length
                );
                setCurrentMessage("");
              }
            }, 1000);
          }

          return nextMessage;
        });
      }
    }, 80);

    return () => {
      isMounted = false;
      clearInterval(letterInterval);
    };
  }, [messageIndex, shuffledMessages]);

  return (
    <div className="flex-1">
      <div className="[background:rgba(255,255,255,0.02)] relative border border-[#2c2a2c] rounded-lg p-[4px] max-w-full md:w-2/3 w-11/12">
        <div className="absolute top-0 right-0 m-2">
          <div className="relative">
            <div
              style={{
                background:
                  "linear-gradient(135deg, rgba(252,16,242,0.90), rgba(48,120,228,0.90))",
              }}
              className="w-2 h-2 bg-blue-500 rounded-full animate-ping"
            ></div>
            <div
              style={{
                background:
                  "linear-gradient(135deg, rgba(252,16,242,0.90), rgba(48,120,228,0.90))",
              }}
              className="w-2 h-2 bg-blue-500 rounded-full absolute top-0 left-0"
            ></div>
          </div>
        </div>
        <div className="flex items-center">
          <div className="relative w-10 h-10 bg-[#232222] rounded-md flex items-center justify-center">
            <div className="inline-block h-9 w-9">
              <img
                src="/Loading.png"
                alt="Loading"
                className="animate-spin object-cover"
              />
            </div>
            {/* <div
              className="inline-block h-8 w-8 animate-spin rounded-full border-2 border-solid border-current border-r-transparent border-[#c01eb8] align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
              role="status"
            >
              <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
                Loading...
              </span>
            </div> */}
          </div>
          <div className="flex flex-col gap-4 animate-none">
            <p className="font-light text-[14px] leading-[17.5px] text-white ml-3 mr-3">
              {currentMessage}
            </p>
            <div className="flex flex-col ml-3 space-y-1">
              <div className="w-36 absolute bottom-2 h-2 bg-[#3c393c] rounded-full overflow-hidden animate-pulse">
                <div className="absolute inset-0 bg-gradient-to-r from-[#3c393c] to-transparent animate-progress"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoadingComponent;
