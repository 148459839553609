import { useState, useEffect } from "react";

const useSidebarState = (initialState = false) => {
  // Initialize the state, checking localStorage for a saved value
  const [isShortSidebar, setIsShortSidebar] = useState(() => {
    const savedSidebarState = localStorage.getItem("isShortSidebar");
    return savedSidebarState !== null
      ? JSON.parse(savedSidebarState)
      : initialState;
  });

  // Save the state to localStorage whenever it changes
  useEffect(() => {
    localStorage.setItem("isShortSidebar", JSON.stringify(isShortSidebar));
  }, [isShortSidebar]);

  // Return the state and the function to update it
  return [isShortSidebar, setIsShortSidebar];
};

export default useSidebarState;
