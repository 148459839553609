import React, { createContext, useContext, useState, useEffect } from "react";
import { getCookie, setCookie, deleteCookie } from "../utils/cookieUtils";
import { jwtDecode } from "jwt-decode"; // Ensure you have this library installed
import { useUserContext } from "./UserContext";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const { updateUserId } = useUserContext();
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const token = getCookie("token");
    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        if (decodedToken.exp * 1000 > Date.now()) {
          setIsLoggedIn(true);
        } else {
          deleteCookie("token");
          deleteCookie("user_id");
          setIsLoggedIn(false);
        }
      } catch (error) {
        console.error("Invalid token:", error);
        deleteCookie("token");
        deleteCookie("user_id");
        setIsLoggedIn(false);
      }
    }
  }, []);

  const login = (token, user_id) => {
    try {
      const decodedToken = jwtDecode(token);
      if (decodedToken.exp * 1000 > Date.now()) {
        setCookie("token", token);
        setCookie("user_id", user_id);
        updateUserId(user_id);
        setIsLoggedIn(true);
      } else {
        console.error("Token has expired.");
      }
    } catch (error) {
      console.error("Invalid token:", error);
    }
  };

  const logout = () => {
    deleteCookie("token");
    deleteCookie("user_id");
    updateUserId(null);
    setIsLoggedIn(false);
  };

  return (
    <AuthContext.Provider value={{ isLoggedIn, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
