import React from "react";

const ChatBoxLoader = ({ className }) => {
  return (
    <div
      className={`mb-6 md:w-auto w-full flex items-start justify-start hover:[background:var(--Color-Opacity-Primary-10,rgba(186,24,236,0.10))] transition duration-500 p-[12px] rounded-lg group relative ${className}`}
    >
      <img
        src="/logo.png"
        alt="AI Avatar"
        className="w-8 h-8 rounded-full bg-[#2c2a2c] p-1"
      />
      <div className="flex-1 ml-3">
        <div className="rounded-lg px-0.5 max-w-full w-full">
          <div className="md:w-[30rem] w-full my-1.5 h-3 bg-[#3c393c] rounded-full relative overflow-hidden animate-pulse">
            <div className="absolute inset-0 bg-gradient-to-r from-[#3c393c] to-transparent animate-progress"></div>
          </div>
          <div className="flex items-center">
            <div className="relative w-8 h-8 bg-[#2c2a2c] rounded-md flex items-center justify-center"></div>
            <div className="flex flex-col gap-2">
              <div className="flex flex-col ml-1.5 space-y-1">
                <div className="w-60 h-2.5 bg-[#3c393c] rounded-full relative overflow-hidden animate-pulse">
                  <div className="absolute inset-0 bg-gradient-to-r from-[#3c393c] to-transparent animate-progress"></div>
                </div>
                <div className="w-20 h-2 bg-[#3c393c] rounded-full relative overflow-hidden animate-pulse">
                  <div className="absolute inset-0 bg-gradient-to-r from-[#3c393c] to-transparent animate-progress"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChatBoxLoader;
