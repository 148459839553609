// LoadingSongs.js
import React from "react";

const LoadingSongs = ({ itemCount }) => {
  return (
    <div>
      <ul className="list-none p-0 m-0">
        {Array.from({ length: itemCount }, (_, index) => (
          <li key={index} className="mb-2">
            <div
              className="flex items-center p-1.5 pr-4 m-1.5 rounded-xl cursor-pointer group animate-pulse"
              style={{
                background: "rgba(255, 255, 255, 0.1)", // Example background color for the placeholder
              }}
            >
              {/* Placeholder for the image */}
              <div className="flex items-center relative overflow-hidden mr-4 w-12 h-12 bg-[#464141] rounded-lg"></div>

              {/* Placeholder for text */}
              <div className="flex flex-col min-w-0 gap-1.5">
                <div className="flex font-medium text-base truncate bg-[#464141] rounded-full h-2 w-40 mb-1"></div>
                <div className="flex font-light text-sm text-gray-400 truncate bg-[#464141] rounded-full h-3 w-60"></div>
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default LoadingSongs;
