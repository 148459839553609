import React, { useState, useEffect } from "react";
import {
  ArrowShapeDownToLine,
  ArrowsRotateLeft,
  ThumbsUp,
  ThumbsDown,
  ThumbsUpFill,
  ThumbsDownFill,
  PlayFill,
  PauseFill,
  ChevronLeft,
  ChevronRight,
  Clock,
  FaceFun,
  EllipsisVertical,
} from "@gravity-ui/icons";
import { useAudioPlayer } from "../../contexts/AudioPlayerContext";
import { useLocalState } from "../../contexts/LocalStateProvider";
import EditableInput from "./EditableInput";
import LoadingComponent from "./LoadingComponent";
import apiService from "../../api/apiService";
import { getCookie } from "../../utils/cookieUtils";
import Toast from "../generic_components/Toast";
import Modal from "../modal/Modal";
import FeedbackContent from "../modal/FeedbackContent"; // Assuming FeedbackContent is located in the modal folder
import ChatBoxLoader from "./ChatBoxLoader";

const AIChatBox = ({
  message,
  relatedMessages = [],
  isLastMessage,
  loadingResponseId,
  beforeRegenerateLoaderId,
  streamingResponseId,
  onRegenerate,
  id,
  passedFromChatInterface,
}) => {
  const {
    isPlaying,
    playAudio,
    pauseAudio,
    resumeAudio,
    audioSrc: currentAudioSrc,
    setAiResponseId,
    setIsBuffering,
    setStreamingSrc,
  } = useAudioPlayer();

  const { localState, updateLocalState } = useLocalState();
  const [showDurationInput, setShowDurationInput] = useState(false);
  const [showTempoInput, setShowTempoInput] = useState(false);
  const [responses, setResponses] = useState([message]);
  const [currentResponseIndex, setCurrentResponseIndex] = useState(0);
  const [likeStatus, setLikeStatus] = useState(null);
  const [toastMessage, setToastMessage] = useState(null);
  const [newResponseAdded, setNewResponseAdded] = useState(false);
  const [isFeedbackModalOpen, setIsFeedbackModalOpen] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [streamingId, setStreamingId] = useState(null);
  const [currentResponseisStreaming, setCurrentResponseIsStreaming] = useState(
    message.isStreaming || false
  );
  const [streamComplete, setStreamComplete] = useState(false);
  const [likeStatuses, setLikeStatuses] = useState({});
  const [isOptionsModalOpen, setIsOptionsModalOpen] = useState(false); // Modal state for small screen options
  const [pageWidth, setPageWidth] = useState(window.innerWidth);

  // useEffect(() => {
  //   // Update responses state when message or relatedMessages change
  //   setResponses([message, ...relatedMessages]);
  //   console.log(responses);
  // }, [message, relatedMessages]);

  useEffect(() => {
    const handleResize = () => {
      setPageWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const newMessages = [message, ...relatedMessages].filter(
      (msg) =>
        !responses.some(
          (response) => response.ai_response_id === msg.ai_response_id
        )
    );

    if (newMessages.length > 0) {
      // Append new messages to the existing ones
      setResponses((prevResponses) => [...prevResponses, ...newMessages]);
      setNewResponseAdded(true); // Set the flag if a new message is added
    }
  }, [message, relatedMessages]);

  useEffect(() => {
    if (
      localState.editedSongDetails &&
      localState.editedSongDetails.ai_response_id ===
        responses[currentResponseIndex].ai_response_id
    ) {
      updateResponseDetails(localState.editedSongDetails);
    }
  }, [localState.editedSongDetails]);

  useEffect(() => {
    if (newResponseAdded) {
      setCurrentResponseIndex(responses.length - 1);
      setNewResponseAdded(false); // Reset the flag after toggling
    }
  }, [responses, newResponseAdded]);

  // useEffect(() => {
  //   if (message.like_count > 0) {
  //     setLikeStatus("like");
  //   } else if (message.dislike_count > 0) {
  //     setLikeStatus("dislike");
  //   } else {
  //     setLikeStatus(null);
  //   }
  // }, [message]);

  useEffect(() => {
    const newLikeStatuses = {};
    [message, ...relatedMessages].forEach((msg) => {
      if (msg.like_count > 0) {
        newLikeStatuses[msg.ai_response_id] = "like";
      } else if (msg.dislike_count > 0) {
        newLikeStatuses[msg.ai_response_id] = "dislike";
      } else {
        newLikeStatuses[msg.ai_response_id] = null;
      }
    });
    setLikeStatuses(newLikeStatuses);
  }, [message, relatedMessages]);

  // useEffect(() => {
  //   if (currentResponse.isStreaming && currentResponse.audio) {
  //     handleStreamingAudio();
  //   }
  // }, []);

  const handleStreamingAudio = async () => {
    if (
      streamingId === null &&
      streamingId !== currentResponse.ai_response_id
    ) {
      try {
        const response = await apiService.sendRequest("checkMusicFileExists", {
          ai_response_id: currentResponse.ai_response_id,
        });

        if (response.success && response.musicFileExists) {
          setCurrentResponseIsStreaming(false);
          setStreamComplete(true);
          setResponses((prevResponses) => {
            const updatedResponses = [...prevResponses];
            updatedResponses[currentResponseIndex] = {
              ...updatedResponses[currentResponseIndex],
              audio: {
                ...updatedResponses[currentResponseIndex].audio,
                src: `${process.env.REACT_APP_SERVER_ENDPOINT}/api/stream-audio/${currentResponse.ai_response_id}`,
              },
            };
            return updatedResponses;
          });

          // Update the version history
          const updatedVersionHistory = localState.versionHistory.map(
            (entry) => {
              if (entry.ai_response_id === currentResponse.ai_response_id) {
                return {
                  ...entry,
                  audio: {
                    ...entry.audio,
                    src: `${process.env.REACT_APP_SERVER_ENDPOINT}/api/stream-audio/${currentResponse.ai_response_id}`,
                  },
                };
              }
              return entry;
            }
          );

          updateLocalState("versionHistory", updatedVersionHistory);

          // passedFromChatInterface
          //   ? setToastMessage("Streaming audio is available from DB.")
          //   : setToastMessage(
          //       "Streaming audio is available from DB for reloaded page."
          //     );
          // Play the audio with the new source
          playAudio(
            `${process.env.REACT_APP_SERVER_ENDPOINT}/api/stream-audio/${currentResponse.ai_response_id}`,
            currentResponse.audio.name,
            currentResponse.musicDescription,
            currentResponse.avatar,
            currentResponse.audio.duration
          );
          return;
        }
      } catch (error) {
        console.error("Error checking music file existence:", error);
      }
    }

    setStreamingId(currentResponse.ai_response_id);
    setIsBuffering(true);
    setStreamingSrc(currentResponse.audio.src);

    fetch(currentResponse.audio.src)
      .then(async (response) => {
        const reader = response.body.getReader();
        let firstChunkReceived = false;

        while (true) {
          // passedFromChatInterface
          //   ? setToastMessage("streaming from redis for newly generated audio")
          //   : setToastMessage("streaming from redis for reloaded page");
          const { done, value } = await reader.read();

          if (done) {
            setStreamingId(null);
            setIsBuffering(false);
            setStreamingSrc(null);
            break;
          }

          if (!firstChunkReceived && value) {
            firstChunkReceived = true;
            playAudio(
              currentResponse.audio.src,
              currentResponse.audio.name,
              currentResponse.musicDescription,
              currentResponse.avatar,
              currentResponse.audio.duration
            );
            continue;
          }
        }
      })
      .catch((error) => {
        console.error("Error streaming audio:", error);
        setStreamingId(null);
        setIsBuffering(false);
        setStreamingSrc(null);
      });
  };

  const handlePlayPause = () => {
    setTimeout(() => {
      pageWidth >= 768 && updateLocalState("isDetailsPanelOpen", true);
      updateLocalState(
        "currentPlayingResponseId",
        responses[currentResponseIndex].ai_response_id
      );
    }, 50);

    if (
      isPlaying &&
      currentAudioSrc === responses[currentResponseIndex].audio.src
    ) {
      pauseAudio();
    } else if (currentAudioSrc === responses[currentResponseIndex].audio.src) {
      resumeAudio();
    } else {
      setAiResponseId(responses[currentResponseIndex].ai_response_id);

      if (message.isStreaming !== false) {
        if (currentResponseisStreaming) {
          handleStreamingAudio();
        } else if (passedFromChatInterface && !streamComplete) {
          // setToastMessage("streaming right now from ai chat box");
          handleStreamingAudio();
        } else if (passedFromChatInterface && streamComplete) {
          // setToastMessage(
          //   "Streaming audio is available from DB and not using handleStreamingAudio function"
          // );
          playAudio(
            responses[currentResponseIndex].audio.src,
            responses[currentResponseIndex].audio.name,
            responses[currentResponseIndex].musicDescription,
            responses[currentResponseIndex].avatar,
            responses[currentResponseIndex].audio.duration
          );
        }
      } else {
        // setToastMessage(
        //   `Playing audio from DB where page reloaded and ${message.isStreaming}`
        // );
        playAudio(
          responses[currentResponseIndex].audio.src,
          responses[currentResponseIndex].audio.name,
          responses[currentResponseIndex].musicDescription,
          responses[currentResponseIndex].avatar,
          responses[currentResponseIndex].audio.duration
        );
      }
    }
  };

  const updateResponseDetails = (newDetails) => {
    setResponses((prevResponses) => {
      const updatedResponses = [...prevResponses];
      updatedResponses[currentResponseIndex] = {
        ...updatedResponses[currentResponseIndex],
        audio: {
          ...updatedResponses[currentResponseIndex].audio,
          name:
            newDetails.name ||
            updatedResponses[currentResponseIndex].audio.name,
          src:
            newDetails.audioSrc ||
            updatedResponses[currentResponseIndex].audio.src,
          duration:
            newDetails.duration ||
            updatedResponses[currentResponseIndex].audio.duration,
        },
        musicDescription:
          newDetails.description ||
          updatedResponses[currentResponseIndex].musicDescription,
        avatar:
          newDetails.coverImage ||
          updatedResponses[currentResponseIndex].avatar,
      };
      return updatedResponses;
    });

    // console.log(responses, "responses");
  };

  const handleActionClick = (action) => {
    // if (action === "Set duration") {
    //   setShowDurationInput(true);
    // } else if (action === "Set tempo") {
    //   setShowTempoInput(true);
    // } else {

    updateLocalState("textPrompt", action + " to ");
  };

  // console.log(message.text);

  const handleAudioClick = () => {
    const currentResponse = responses[currentResponseIndex];

    const detailsData = {
      name: currentResponse.audio.name,
      description: currentResponse.musicDescription,
      coverImage: currentResponse.avatar,
      duration: currentResponse.audio.duration,
      audioSrc: currentResponse.audio.src,
      ai_response_id: currentResponse.ai_response_id,
      projectId: currentResponse.projectId,
    };

    setTimeout(() => {
      !localState.isDetailsPanelOpen &&
        updateLocalState("isDetailsPanelOpen", true);
    }, 100);
    updateLocalState("currentSongDetails", detailsData); // Store details in local state
  };

  const handleLikeDislike = async (isLike) => {
    const userId = getCookie("user_id");
    const currentResponse = responses[currentResponseIndex];

    if (!userId || !currentResponse.ai_response_id) return;

    const payload = {
      response_id: currentResponse.ai_response_id,
      user_id: userId,
      is_like: isLike,
    };

    try {
      const currentLikeStatus = likeStatuses[currentResponse.ai_response_id];

      if (currentLikeStatus === (isLike ? "like" : "dislike")) {
        // If the current state is already like/dislike, remove the entry
        await apiService.sendRequest("removeAIResponseLike", payload);
        setLikeStatuses((prev) => ({
          ...prev,
          [currentResponse.ai_response_id]: null,
        }));
      } else {
        // Otherwise, add or update the like/dislike
        await apiService.sendRequest("updateAIResponseLike", payload);
        setLikeStatuses((prev) => ({
          ...prev,
          [currentResponse.ai_response_id]: isLike ? "like" : "dislike",
        }));

        if (!isLike) setIsFeedbackModalOpen(true);
      }
    } catch (error) {
      console.error("Error updating like/dislike:", error);
    }
  };

  const handleDownload = async () => {
    const currentResponse = responses[currentResponseIndex];

    gtag("event", "download_music", {
      event_category: "Music Actions",
      event_label: "Download Button",
      value: 1,
    });

    if (streamingId === currentResponse.ai_response_id) {
      // If the audio is still streaming, show a message or prevent download
      setToastMessage("Audio is still streaming; download is not available.");
    } else if (currentResponse.audio) {
      try {
        setIsDownloading(true);
        // If streaming is done, download the complete audio file
        const response = await apiService.sendRequest(
          "fetchCompleteAIResponseAudio",
          {
            ai_response_id: currentResponse.ai_response_id,
          }
        );
        const { audioFile } = response;

        if (response.success) {
          const blob = new Blob([new Uint8Array(audioFile.data)], {
            type: "audio/wav",
          });
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = currentResponse.audio.name || "audio.wav";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        } else {
          response?.message
            ? setToastMessage(response?.message)
            : setToastMessage("Failed to fetch the audio file for download.");
        }
      } catch (error) {
        console.error("Error downloading audio:", error);
        setToastMessage("An error occurred while downloading the audio.");
      } finally {
        setIsDownloading(false);
      }
    }
  };

  const handlePreviousResponse = () => {
    if (currentResponseIndex > 0) {
      setCurrentResponseIndex(currentResponseIndex - 1);
    }
  };

  const handleNextResponse = () => {
    if (currentResponseIndex < responses.length - 1) {
      setCurrentResponseIndex(currentResponseIndex + 1);
    }
  };

  const currentResponse = responses[currentResponseIndex];

  // If this response is the one currently loading, show the LoadingComponent
  const isLoading = loadingResponseId === currentResponse.ai_response_id;

  // Determine if this response is still streaming
  const isStreaming = currentResponseisStreaming
    ? streamingId === currentResponse.ai_response_id
    : streamingResponseId === currentResponse.ai_response_id;

  const loadingBeforeRegenerate =
    beforeRegenerateLoaderId === currentResponse.ai_response_id;

  if (loadingBeforeRegenerate) {
    return (
      <div className="flex-1 mt-auto flex items-center w-full max-w-screen-lg lg:max-w-screen-md">
        <ChatBoxLoader className={"w-full"} />
      </div>
    );
  }
  if (isLoading) {
    // If loading, render LoadingComponent and the current response text
    return (
      <div className="mb-6 flex items-start hover:[background:var(--Color-Opacity-Primary-10,rgba(186,24,236,0.10))] transition duration-500 p-3 rounded-lg group relative w-full max-w-screen-lg lg:max-w-screen-md">
        <img
          src="/logo.png"
          alt="AI Avatar"
          className="w-8 h-8 rounded-full bg-[#2c2a2c] p-1"
        />
        <div className="flex-1 inline-block w-full">
          <div className="flex-col mt-1 mb-1 ml-3">
            <p className="font-extralight text-base text-white mb-3">
              {currentResponse.text}
            </p>
            <LoadingComponent />
          </div>
        </div>
      </div>
    );
  }

  const handleOptionsClick = () => {
    setIsOptionsModalOpen(true);
  };

  const handleOptionsClose = () => {
    setTimeout(() => {
      setIsOptionsModalOpen(false);
    }, 1000);
  };

  return (
    <div
      id={id}
      className="mb-6 flex items-start hover:[background:var(--Color-Opacity-Primary-10,rgba(186,24,236,0.10))] transition duration-500 p-3 rounded-lg group relative w-full max-w-screen-lg lg:max-w-screen-md"
    >
      <img
        src="/logo.png"
        alt="AI Avatar"
        className="w-8 h-8 rounded-full bg-[#2c2a2c] p-1"
      />
      <div className="flex-1 inline-block">
        <div className="flex-col mt-1 mb-1 ml-3">
          <p
            className={`font-extralight text-base text-white ${
              currentResponse.audio ? "mb-3" : ""
            }`}
          >
            {currentResponse.text}
          </p>
          <div className="flex flex-row items-center md:w-fit w-full">
            <div
              className={`self-stretch md:w-fit w-full ${
                currentResponse.audio ? "mb-1" : ""
              } rounded-lg inline-block`}
            >
              {currentResponse.audio && (
                <div
                  id="audio-container"
                  className="flex items-center overflow-hidden rounded-lg border border-solid border-[#3c393c] p-1.5 pr-2.5 relative md:w-fit w-full"
                >
                  {isStreaming && (
                    <div className="absolute top-0 right-0 m-2">
                      <div className="relative">
                        <div
                          style={{
                            background:
                              "linear-gradient(135deg, rgba(252,16,242,0.90), rgba(48,120,228,0.90))",
                          }}
                          className="w-2 h-2 bg-blue-500 rounded-full animate-ping"
                        ></div>
                        <div
                          style={{
                            background:
                              "linear-gradient(135deg, rgba(252,16,242,0.90), rgba(48,120,228,0.90))",
                          }}
                          className="w-2 h-2 bg-blue-500 rounded-full absolute top-0 left-0"
                        ></div>
                      </div>
                    </div>
                  )}
                  <img
                    src={currentResponse.avatar}
                    alt="Cover"
                    className="w-11 h-11 object-cover rounded-md mr-2.5 transition-opacity duration-500 group-hover:opacity-60"
                  />
                  <div
                    id="open-details-panel-from-ai-chatbox"
                    className="flex flex-row cursor-pointer md:w-fit w-full gap-1 mr-2"
                    onClick={() => {
                      handleAudioClick();
                    }}
                  >
                    <div className="flex flex-col w-full justify-center md:w-fit">
                      <h2 className="font-normal text-base md:w-fit w-full">
                        {currentResponse.audio.name}
                      </h2>
                      <div className="flex flex-row w-full items-center md:w-fit gap-3 max-[640px]:justify-between">
                        <span className="font-extralight text-sm text-[#878188] md:w-fit w-full max-[640px]:truncate max-[360px]:max-w-28 max-[400px]:max-w-36 max-[440px]:max-w-44 max-[480px]:max-w-52 max-[520px]:max-w-60 max-[560px]:max-w-[17rem] max-[600px]:max-w-[19rem] max-[640px]:max-w-[21rem]">
                          {currentResponse.musicDescription}
                        </span>
                        <span className="flex gap-1 text-[#878188] font-extralight text-sm justify-center items-center mt-auto">
                          <Clock className="w-3.5" />
                          {currentResponse.audio.duration}
                        </span>
                      </div>
                    </div>
                  </div>
                  <button
                    id="play-pause-button"
                    onClick={() => {
                      handlePlayPause();
                    }}
                    className={`absolute flex left-[1.125rem] items-center text-white transition-transform duration-500 transform group-hover:translate-y-0 ${
                      isPlaying && currentAudioSrc === currentResponse.audio.src
                        ? ""
                        : "translate-y-[300%] group-hover:opacity-100"
                    }`}
                  >
                    {isPlaying &&
                    currentAudioSrc === currentResponse.audio.src ? (
                      <PauseFill className="w-4 h-4" />
                    ) : (
                      <PlayFill className="w-4 h-4" />
                    )}
                  </button>
                </div>
              )}

              {relatedMessages.length > 0 && (
                <div className="flex justify-start items-center mt-2 text-gray-400">
                  <button
                    id="previous-response-button"
                    onClick={handlePreviousResponse}
                    disabled={currentResponseIndex === 0}
                  >
                    <ChevronLeft className="w-4 h-4" />
                  </button>
                  <span className="text-sm font-extralight">
                    {currentResponseIndex + 1}/{responses.length}
                  </span>
                  <button
                    id="next-response-button"
                    onClick={handleNextResponse}
                    disabled={currentResponseIndex === responses.length - 1}
                  >
                    <ChevronRight className="w-4 h-4" />
                  </button>
                </div>
              )}
            </div>
            {/* Show ellipsis icon on small screens */}
            {currentResponse.audio && (
              <div
                id="ai-response-action-buttons"
                className="flex space-x-2 md:hidden ml-2.5"
              >
                <button
                  id="options-button"
                  onClick={handleOptionsClick}
                  className="hover:text-white p-2 rounded-md text-[#878188]"
                >
                  <EllipsisVertical />
                </button>
              </div>
            )}
            <div
              id="ai-response-action-buttons"
              className={`hidden md:flex ${
                relatedMessages.length > 0
                  ? `pb-10`
                  : currentResponse.audio && `pb-0 md:ml-2.5`
              } space-x-2 opacity-0 group-hover:opacity-100 transition-opacity duration-500`}
            >
              {isLastMessage && currentResponse.audio && (
                <button
                  id="regenerate-button"
                  onClick={() => onRegenerate(currentResponse.ai_response_id)}
                  className="hover:text-white hover:[background:var(--Color-Opacity-White-5,rgba(255,255,255,0.05))] p-2 rounded-md text-[#878188]"
                >
                  <ArrowsRotateLeft />
                </button>
              )}
              {currentResponse.audio && (
                <div className="flex space-x-2">
                  <button
                    id="download-button"
                    onClick={handleDownload}
                    className="hover:text-white hover:bg-[rgba(255,255,255,0.05)] p-2 rounded-md text-[#878188]"
                    // disabled={isStreaming}
                  >
                    {isDownloading ? (
                      <div className="w-4 h-4 border-2 border-t-2 border-white border-t-transparent rounded-full animate-spin"></div>
                    ) : (
                      <ArrowShapeDownToLine />
                    )}
                  </button>
                  <button
                    id="like-button"
                    onClick={() => handleLikeDislike(true)}
                    className="hover:text-white hover:bg-[rgba(255,255,255,0.05)] p-2 rounded-md text-[#878188]"
                  >
                    {likeStatuses[currentResponse.ai_response_id] === "like" ? (
                      <ThumbsUpFill />
                    ) : (
                      <ThumbsUp />
                    )}
                  </button>
                  <button
                    id="dislike-button"
                    onClick={() => handleLikeDislike(false)}
                    className="hover:text-white hover:bg-[rgba(255,255,255,0.05)] p-2 rounded-md text-[#878188]"
                  >
                    {likeStatuses[currentResponse.ai_response_id] ===
                    "dislike" ? (
                      <ThumbsDownFill />
                    ) : (
                      <ThumbsDown />
                    )}
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>

        {isLastMessage && currentResponse.actions && (
          <div className="flex space-x-2 ml-3">
            {currentResponse.actions.map((action, index) => (
              <div
                key={index}
                className="p-px hover:bg-gradient-to-r from-[#fc10f2] to-[#3078e4] transition duration-500 rounded-lg"
              >
                <div className="flex bg-[#100f10] border border-solid border-[#2c2a2c] rounded-lg items-center">
                  <button
                    id={`action-${action.replace(" ", "-")}`}
                    onClick={() => handleActionClick(action)}
                    className="flex flex-row h-8 items-center  md:p-0 md:px-2 p-2 gap-1 hover:text-white font-normal text-sm text-[#878188]"
                  >
                    {action === "Extend duration" && (
                      <img
                        src="/ChangeDuration.svg"
                        className="h-4 w-4"
                        alt="Change duration"
                      />
                    )}
                    {action === "Change genre" && (
                      <FaceFun className="h-4 w-4 text-[#878188]" />
                    )}
                    <span className="hidden md:flex ">{action}</span>
                  </button>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
      {toastMessage && (
        <Toast
          type="error"
          message={toastMessage}
          onClose={() => setToastMessage(null)}
        />
      )}

      {isFeedbackModalOpen && (
        <Modal closeModal={() => setIsFeedbackModalOpen(false)}>
          <FeedbackContent
            responseId={currentResponse.ai_response_id}
            onClose={() => setIsFeedbackModalOpen(false)}
            onSubmit={async () => {
              // Add your submit logic here
              setIsFeedbackModalOpen(false);
            }}
          />
        </Modal>
      )}
      {/* Options Modal for small screens */}
      {isOptionsModalOpen && (
        <Modal closeModal={handleOptionsClose}>
          <div className="flex flex-col gap-0 w-full px-2 pt-10 pb-3 bg-[#1d1b1d] rounded-lg">
            {isLastMessage && currentResponse.audio && (
              <button
                id="regenerate-button"
                onClick={() => {
                  onRegenerate(currentResponse.ai_response_id);
                  handleOptionsClose();
                }}
                className="flex items-center gap-3 hover:text-white hover:bg-[rgba(255,255,255,0.05)] p-3 rounded-md text-[#878188] transition-colors duration-300"
              >
                <ArrowsRotateLeft className="w-5 h-5" />
                <span>Regenerate</span>
              </button>
            )}
            <button
              onClick={() => {
                handleDownload();
                handleOptionsClose();
              }}
              className="flex items-center gap-3 hover:text-white hover:bg-[rgba(255,255,255,0.05)] p-3 rounded-md text-[#878188] transition-colors duration-300"
            >
              <ArrowShapeDownToLine className="w-5 h-5" />
              <span>Download</span>
            </button>
            <button
              id="like-button"
              onClick={() => {
                handleLikeDislike(true);
                handleOptionsClose();
              }}
              className="flex items-center gap-3 hover:text-white hover:bg-[rgba(255,255,255,0.05)] p-3 rounded-md text-[#878188] transition-colors duration-300"
            >
              {likeStatuses[currentResponse.ai_response_id] === "like" ? (
                <ThumbsUpFill className="w-5 h-5" />
              ) : (
                <ThumbsUp className="w-5 h-5" />
              )}
              <span>Like</span>
            </button>
            <button
              id="dislike-button"
              onClick={() => {
                handleLikeDislike(false);
                handleOptionsClose();
              }}
              className="flex items-center gap-3 hover:text-white hover:bg-[rgba(255,255,255,0.05)] p-3 rounded-md text-[#878188] transition-colors duration-300"
            >
              {likeStatuses[currentResponse.ai_response_id] === "dislike" ? (
                <ThumbsDownFill className="w-5 h-5" />
              ) : (
                <ThumbsDown className="w-5 h-5" />
              )}
              <span>Dislike</span>
            </button>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default AIChatBox;
