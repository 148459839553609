import React, { useRef } from "react";
import { ArrowShapeUpFromLine, Xmark } from "@gravity-ui/icons";

const ModalContent = ({ title, fileTypes, handleFileChange, closeModal }) => {
  const dropRef = useRef(null);

  const handleDrop = (e) => {
    e.preventDefault();
    const files = e.dataTransfer.files;
    if (files.length > 0) {
      handleFileChange({ target: { files } });
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  return (
    <div className="relative text-left bg-[#1d1b1d] border border-solid border-[#3c393c] rounded-lg w-full md:w-[32rem] self-center">
      <button
        onClick={closeModal}
        className="absolute top-2 right-2 p-2 z-10 justify-center rounded-full hover:[background:var(--Color-Opacity-White-5,rgba(255,255,255,0.05))] text-white"
      >
        <Xmark className="w-4 h-4" />
      </button>
      <h2 className="text-xl font-medium text-left w-full px-3 py-3">
        {title}
      </h2>
      <div className="w-full border-t border-t-[#3c393c] mb-3"></div>
      <div
        ref={dropRef}
        className="border-dashed m-3 border-2 border-[#3c393c] px-5 py-5 rounded-md text-center"
        onDrop={handleDrop}
        onDragOver={handleDragOver}
      >
        <ArrowShapeUpFromLine className="w-[35px] h-[34.999515533447266px] text-white mx-auto mb-3" />
        <p className="mb-1 text-sm">
          Drop a file here or{" "}
          <label
            htmlFor="fileInput"
            className="text-white cursor-pointer underline"
          >
            Browse
          </label>
        </p>
        <input
          type="file"
          accept={fileTypes.join(",")}
          onChange={handleFileChange}
          className="hidden"
          id="fileInput"
        />
        <p className="text-gray-400 font-light text-sm">
          File supported: {fileTypes.join(", ")}
        </p>
        <p className="text-gray-400 font-light text-sm">File size: max 4 MB</p>
        <div className="flex space-x-2 justify-center mt-4">
          <button className="h-9 w-9 md:w-fit sm:w-fit flex gap-2 justify-center items-center font-medium text-sm text-[#878188] bg-[#100f10] md:px-3 sm:px-2 rounded-lg border border-solid border-[#2c2a2c]">
            <img src="/dropbox.png" alt="Dropbox" className="w-4 h-4" />
            <span className="hidden md:flex sm:flex">Upload via Dropbox</span>
          </button>
          <button className="h-9 w-9 md:w-fit sm:w-fit flex gap-2 justify-center items-center font-medium text-sm text-[#878188] bg-[#100f10] md:px-3 sm:px-2 rounded-lg border border-solid border-[#2c2a2c]">
            <img src="/GDrive.png" alt="Google Drive" className="w-4 h-4" />
            <span className="hidden sm:flex md:flex">
              Upload via Google Drive
            </span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default ModalContent;
