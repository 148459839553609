import React, { useState, useRef, useEffect } from "react";
import {
  PencilToLine,
  ArrowLeft,
  Ellipsis,
  CircleExclamation,
  TrashBin,
  Check,
  Xmark,
} from "@gravity-ui/icons";
import Modal from "../modal/Modal";
import InputWithButton from "../generic_components/InputWithButton";
import { useLocalState } from "../../contexts/LocalStateProvider";
import apiService from "../../api/apiService"; // Import apiService
import { useNavigate } from "react-router-dom";

const Header = ({
  projectName,
  lastEdited,
  onProjectNameChange,
  projectId,
  loading,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editedProjectName, setEditedProjectName] = useState("");
  const [showDropdown, setShowDropdown] = useState(false);
  const dropdownRef = useRef(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const { updateLocalState } = useLocalState();
  const { localState } = useLocalState();
  const [pageWidth, setPageWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setPageWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const navigate = useNavigate();

  const handleBackClick = () => {
    navigate(-1); // This will take the user to the previous page
  };

  const handleEditClick = () => {
    setIsEditing(true);
  };

  useEffect(() => {
    if (!editedProjectName && projectName && !isEditing) {
      setEditedProjectName(projectName);
    }
  }, [projectName]); // Dependencies

  useEffect(() => {
    if (localState.projectNameUpdated) {
      setEditedProjectName(localState.projectName);
      updateLocalState("projectNameUpdated", false);
    }
  }, [localState.projectNameUpdated, localState.projectName]);

  const handleSaveClick = async () => {
    if (editedProjectName !== projectName) {
      try {
        const payload = {
          projectId,
          project_name: editedProjectName,
          set_by_user: true,
        };
        await apiService.sendRequest("updateProjectName", payload);

        updateLocalState("projectNameSetByUser", true);

        // Call onProjectNameChange to update the parent component with the new name
        if (onProjectNameChange) {
          onProjectNameChange(editedProjectName);
        }
      } catch (error) {
        console.error("Failed to update project name:", error);
      }
    }
    setIsEditing(false);
  };

  const handleCancelClick = () => {
    setIsEditing(false);
    setEditedProjectName(projectName); // Revert to original name
  };

  const handleDropdownToggle = () => {
    setShowDropdown(!showDropdown);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setShowDropdown(false);
    }
  };

  useEffect(() => {
    setEditedProjectName(projectName);

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleDeleteProject = () => {
    setShowDeleteModal(true);
  };

  const closeModal = () => {
    setShowDeleteModal(false);
  };

  const confirmDeleteProject = async () => {
    try {
      const payload = { projectId };
      await apiService.sendRequest("deleteProject", payload);

      // Handle any additional logic after deletion, such as redirecting or updating state
    } catch (error) {
      console.error("Failed to delete project:", error);
    } finally {
      setShowDeleteModal(false);
    }
  };

  const handleOpenDetailsPanel = () => {
    updateLocalState("isDetailsPanelOpen", true);
  };

  return (
    <div ref={dropdownRef} className="flex items-center relative px-4 py-3  ">
      <button
        id="back-button"
        onClick={handleBackClick}
        className="md:p-2 p-2.5 justify-center items-center bg-[#2c2a2c] rounded-full"
      >
        <ArrowLeft />
      </button>
      <div className="flex flex-1 justify-center items-center">
        {isEditing ? (
          <>
            {pageWidth <= 768 ? (
              <>
                <input
                  id="project-name-input"
                  type="text"
                  className="px-3 md:h-8 h-9 md:text-xs text-sm rounded-full border border-solid border-[#878188] bg-transparent text-white mx-1 w-full max-w-48"
                  value={editedProjectName}
                  onChange={(e) => setEditedProjectName(e.target.value)}
                />
                <button
                  id="cancel-edit-button"
                  className="mr-1 text-xs font-normal px-2 h-8 hover:[background:var(--Color-Opacity-White-5,rgba(255,255,255,0.05))] text-[#878188] hover:text-white rounded-full"
                  onClick={handleCancelClick}
                >
                  <Xmark />
                </button>
                <button
                  id="save-edit-button"
                  className="h-8 text-xs bg-white hover:bg-[#E0DFE1] px-2 text-black font-normal rounded-full"
                  onClick={handleSaveClick}
                >
                  <Check />
                </button>
              </>
            ) : (
              <>
                <input
                  id="project-name-input"
                  type="text"
                  className="px-3 h-8 text-xs rounded-full border border-solid border-[#878188] bg-transparent text-white mr-2 w-full max-w-48"
                  value={editedProjectName}
                  onChange={(e) => setEditedProjectName(e.target.value)}
                />
                <button
                  id="cancel-edit-button"
                  className="mr-1 text-xs font-normal px-3 h-8 hover:[background:var(--Color-Opacity-White-5,rgba(255,255,255,0.05))] text-[#878188] hover:text-white rounded-full"
                  onClick={handleCancelClick}
                >
                  Cancel
                </button>
                <button
                  id="save-edit-button"
                  className="h-8 text-xs bg-white hover:bg-[#E0DFE1] px-3 text-black font-normal rounded-full"
                  onClick={handleSaveClick}
                >
                  Save
                </button>
              </>
            )}
          </>
        ) : (
          <h1 className="text-lg font-normal">{editedProjectName}</h1>
        )}
        <div className="flex items-center ml-2">
          {!loading && !isEditing && (
            <button
              id="edit-project-name-button"
              className="text-xl ml-2"
              onClick={handleEditClick}
            >
              <PencilToLine className="w-4 h-4 text-[#878188]" />
            </button>
          )}
        </div>
      </div>
      <div className="flex items-center ml-auto">
        <button
          id="dropdown-toggle-button"
          onClick={handleDropdownToggle}
          className="bg-[#2c2a2c] md:p-2 p-2.5 rounded-full"
        >
          <Ellipsis />
        </button>
        {showDropdown && (
          <div
            id="chatpage-project-details-dropdown"
            ref={dropdownRef}
            className="absolute right-3 top-14 text-center font-normal text-sm text-[#878188] bg-[#100f10] py-1 px-1 rounded-lg border border-solid border-[#2c2a2c] w-36 z-20"
          >
            <button
              id="view-details-button"
              onClick={handleOpenDetailsPanel}
              className="flex items-center justify-left gap-2 hover:text-white h-8 px-2 rounded-lg hover:bg-zinc-900 w-full"
            >
              <CircleExclamation />
              View Details
            </button>
            <button
              id="delete-project-button"
              onClick={handleDeleteProject}
              className="flex items-center justify-left gap-2 h-8 hover:text-white px-2 rounded-lg hover:bg-zinc-900 w-full"
            >
              <TrashBin /> Delete project
            </button>
          </div>
        )}
        {showDeleteModal && (
          <Modal closeModal={closeModal}>
            <div className="max-w-96 pb-3">
              <h3 className="text-left text-base p-3">Delete Project</h3>
              <div className="w-full border-t border-t-[#2d2a2d]"></div>
              <InputWithButton
                formClassName="space-y-4 w-full px-3"
                inputFields={[
                  {
                    type: "info",
                    content: ["Are you sure you want to delete this project?"],
                    subContent:
                      "This will delete all the generated music and comments. Do you want to proceed?",
                    className:
                      "text-white text-left text-sm font-extralight mb-4 mt-3",
                  },
                ]}
                buttonClassName="px-3 mr-3 h-8 text-sm bg-red-600 hover:bg-[#BB2D2D] text-white rounded-full"
                buttonText="Delete"
                onButtonClick={confirmDeleteProject} // Call confirmDeleteProject when delete is clicked
                cancelButtonClassName="px-3 h-8 text-sm rounded-full hover:[background:var(--Color-Opacity-White-5,rgba(255,255,255,0.05))] text-white"
                cancelButtonText="Cancel"
                onCancelClick={closeModal}
                closeModal={closeModal}
                cancelButtonId={"delete-project-cancel-button"}
                actionButtonId={"delete-project-confirm-button"}
                requestData={{
                  taskName: "deleteProject",
                  payload: {
                    project_id: projectId,
                  },
                }}
              />
            </div>
          </Modal>
        )}
      </div>
    </div>
  );
};

export default Header;
