import React from "react";

const ProjectCardLoader = ({ itemCount }) => (
  <div className="p-4 flex-wrap grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4">
    {Array.from({ length: itemCount }).map((_, index) => (
      <div
        key={index}
        className="relative flex flex-col gap-2 px-2 pt-2 pb-3 grow bg-[#1d1b1d] animate-pulse rounded-xl"
      >
        <div className="h-48 bg-[#3d3939] rounded-lg" />
        <div className="flex flex-col self-stretch px-1">
          <span className="h-4 bg-[#373535] rounded w-3/4 mb-2" />
          <span className="h-3 bg-[#373535] rounded w-1/2" />
        </div>
      </div>
    ))}
  </div>
);

export default ProjectCardLoader;
