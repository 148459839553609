import React, { useState, useEffect } from "react";
import fetchDataAndBuildJson from "../../utils/fetchDataAndBuildJson";
import { componentMapper } from "../../utils/componentMapper";
import Toast from "../generic_components/Toast";
import { useAuth } from "../../contexts/AuthContext";
import useSidebarState from "../hooks/useSidebarState";
import Modal from "../modal/Modal";
import { useNavigate } from "react-router-dom";
import { ArrowUpRightFromSquare } from "@gravity-ui/icons";
import { getCookie } from "../../utils/cookieUtils";
import { useLocalState } from "../../contexts/LocalStateProvider";

const Settings = () => {
  const [activeTab, setActiveTab] = useState("personal");
  const [settingsData, setSettingsData] = useState(null);
  const [infoMessage, setInfoMessage] = useState("");
  const { isLoggedIn } = useAuth();
  const [isShortSidebar, setIsShortSidebar] = useSidebarState(false);
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const [isTeamMember, setIsTeamMember] = useState(true);
  const navigate = useNavigate();
  const { localState, updateLocalState } = useLocalState();

  useEffect(() => {
    const fetchSettings = async () => {
      if (localState.organizationClicked) {
        setActiveTab("organization");
        updateLocalState("organizationClicked", false);
      }
      if (activeTab == "personal") {
        if (!localState.userProfileData) {
          const data = await fetchDataAndBuildJson(
            "fetchUserProfile",
            "buildSettingsJson.js",
            { updateLocalState }
          );
          setSettingsData(data);
        } else if (localState.userProfileData) {
          const data = await fetchDataAndBuildJson(
            "fetchUserProfile",
            "buildSettingsJson.js",
            { localStateData: localState.userProfileData, updateLocalState },
            true
          );
          setSettingsData(data);
        }
      } else if (activeTab == "profile") {
        if (!localState.userProfileData) {
          const data = await fetchDataAndBuildJson(
            "fetchUserProfile",
            "buildProfileSettingsJson.js",
            { updateLocalState }
          );
          setSettingsData(data);
        } else if (localState.userProfileData) {
          const data = await fetchDataAndBuildJson(
            "fetchUserProfile",
            "buildProfileSettingsJson.js",
            { localStateData: localState.userProfileData, updateLocalState },
            true
          );
          setSettingsData(data);
        }
      } else {
        if (!localState.teamSettingsData) {
          const data = await fetchDataAndBuildJson(
            "fetchTeamProfile",
            "buildTeamSettingsJson.js",
            { updateLocalState }
          );
          setSettingsData(data);
        } else if (localState.teamSettingsData) {
          const data = await fetchDataAndBuildJson(
            "fetchTeamProfile",
            "buildTeamSettingsJson.js",
            { localStateData: localState.teamSettingsData, updateLocalState },
            true
          );
          setSettingsData(data);
        }
      }
    };

    fetchSettings();
  }, [activeTab, localState.organizationClicked]);

  useEffect(() => {
    // Check if organizationInviteClicked is true and focus the invite input
    if (localState.organizationInviteClicked) {
      setTimeout(
        () => {
          const inviteSection = document.getElementById("invite-section");
          if (inviteSection) {
            inviteSection.scrollIntoView({ block: "center" });
            const inviteInput = document.querySelector(
              'input[placeholder="Enter email address to send invite"]'
            );
            if (inviteInput) {
              inviteInput.focus();
            }
          }
          updateLocalState("organizationInviteClicked", false); // Reset after handling
        },
        !localState.teamSettingsData ? 2000 : 100
      );
    }
  }, [localState.organizationInviteClicked]);

  useEffect(() => {
    const checkTeamMembership = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_SERVER_ENDPOINT}/api/check-team-membership`,
          {
            headers: {
              Authorization: `Bearer ${getCookie("token")}`,
            },
          }
        );

        const data = await response.json();

        setIsTeamMember(data.isTeamMember);
      } catch (error) {
        console.error("Error checking team membership:", error);
      }
    };

    checkTeamMembership();
  }, []);

  const handleTabClick = (tab) => {
    if (tab === "organization" && !isTeamMember) {
      navigate("/create-team");
    } else {
      setActiveTab(tab);
    }
  };

  const renderComponents = (data) => {
    if (!Array.isArray(data)) {
      console.error("Invalid jsonData format or empty array:", data);
      return null;
    }

    return data.map((item, index) => {
      const { name, props, children } = item;

      // Handle inputJson key to import JSON pages into other JSON pages
      if (item.inputJson && location?.pathname !== "/chatPage") {
        const jsonFile = require(`../../jsonFiles/${item.inputJson}`);
        return <div key={index}>{renderComponents(jsonFile)}</div>;
      }

      // Check if the item has a conditionalJson key
      if (item.conditionalJson) {
        // Determine which JSON file to load based on the sidebar state and login status
        const jsonFileName = isLoggedIn
          ? isShortSidebar
            ? item.conditionalJson.trueJsonAfterLogin
            : item.conditionalJson.falseJsonAfterLogin
          : isShortSidebar
          ? item.conditionalJson.trueJson
          : item.conditionalJson.falseJson;

        // If the sidebar is not short and there is no falseJson, skip rendering
        if (!isShortSidebar && !jsonFileName) {
          return null;
        }

        // Load and render the appropriate sidebar JSON file if it exists
        if (jsonFileName) {
          const jsonFile = require(`../../jsonFiles/${jsonFileName}`);
          return renderComponents(jsonFile);
        }
      }

      // Add the onClick function to the specific button to toggle the sidebar state
      if (name === "button" && props.trigger === "toggleSidebar") {
        props.onClick = () => setIsShortSidebar((prev) => !prev);
      }

      const ComponentToRender = componentMapper[name];

      if (!ComponentToRender) {
        return null;
      }

      const componentProps = {
        ...props,
        ...(props?.trigger === "toggleModal"
          ? {
              onClick: () => {
                setModalContent(props.modalContent);
                setShowModal(true);
              },
            }
          : {}),
        ...(props?.trigger === "closeModal"
          ? {
              onClick: () => setShowModal(false),
            }
          : {}),
        ...(name === "InputWithButton"
          ? { closeModal: () => setShowModal(false) }
          : {}),
      };

      return (
        <React.Fragment key={index}>
          {infoMessage && (
            <Toast
              key={`toast-${index}`}
              type="info"
              message={infoMessage}
              onClose={() => setInfoMessage("")}
            />
          )}
          <ComponentToRender {...componentProps}>
            {children && renderComponents(children)}
          </ComponentToRender>
        </React.Fragment>
      );
    });
  };

  return (
    <div className="bg-[#100f10] w-full border border-[#2e2e2d] rounded-md">
      <div className="flex flex-row justify-center px-4 border-b border-b-[#2C2A2C]">
        <div className="flex flex-row justify-between items-center md:px-3 w-full max-w-3xl mx-auto">
          <h1 className="hidden md:block text-2xl font-normal text-white">
            Settings
          </h1>
          <div className="md:flex md:w-auto flex flex-row md:justify-around w-full">
            <button
              className={`md:mr-5 w-full px-0 h-14 ${
                activeTab === "personal"
                  ? "text-white border-b border-b-white"
                  : "text-[#878188] hover:text-white duration-200"
              }`}
              onClick={() => handleTabClick("personal")}
            >
              Account
            </button>
            <button
              className={`md:mr-5 w-full px-0 h-14 ${
                activeTab === "profile"
                  ? "text-white border-b border-b-white"
                  : "text-[#878188] hover:text-white duration-200"
              }`}
              onClick={() => handleTabClick("profile")}
            >
              Profile
            </button>
            <button
              className={`flex w-full ${
                !isTeamMember && `hover:text-white duration-200`
              } flex-row items-center gap-2 justify-center px-0 h-14 ${
                activeTab === "organization"
                  ? "text-white border-b border-b-white"
                  : "text-[#878188] hover:text-white duration-200"
              }`}
              onClick={() => handleTabClick("organization")}
            >
              <p className="flex text-nowrap">
                {!isTeamMember ? "Create a Team" : "Team"}
              </p>
              {!isTeamMember && <ArrowUpRightFromSquare className="mt-0.5" />}
            </button>
          </div>
        </div>
      </div>

      <div className="flex flex-col flex-1 w-full justify-center">
        {settingsData && renderComponents(settingsData)}
      </div>
      {showModal && (
        <Modal closeModal={() => setShowModal(false)}>
          {modalContent && renderComponents([modalContent])}
        </Modal>
      )}
    </div>
  );
};

export default Settings;
