import Cookies from "js-cookie";

// Set a cookie
export const setCookie = (name, value, days) => {
  Cookies.set(name, value, {
    expires: days,
    secure: true,
    sameSite: "Strict",
    path: "/",
  });
};

// Get a cookie
export const getCookie = (name) => {
  return Cookies.get(name);
};

// Delete a cookie
export const deleteCookie = (name) => {
  Cookies.remove(name, {
    path: "/",
  });
};
