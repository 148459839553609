import { useNavigate } from "react-router-dom";

const Image = (props) => {
  const navigate = useNavigate();

  const { onClick, path, ...restProps } = props;

  return onClick ? (
    <img
      {...restProps}
      onClick={() => {
        path && navigate(path);
      }}
    />
  ) : (
    <img {...restProps} />
  );
};

export default Image;
