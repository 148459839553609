import React, { useState, useEffect, useRef } from "react";
import ProjectCard from "./ProjectCard";
import ProjectListView from "./ProjectListView"; // Import ProjectListView component
import apiService from "../../api/apiService";
import Toast from "../../components/generic_components/Toast";
import { useLocalState } from "../../contexts/LocalStateProvider";
import { Bars, Rectangles4, ChevronDown, ChevronUp } from "@gravity-ui/icons";
import ProjectCardLoader from "./ProjectCardLoader";
import LoadingSongs from "../custom_components/LoadingSongs";

// const LIMIT = 20; // Number of projects to fetch per batch

const ProjectGrid = () => {
  const [projects, setProjects] = useState([]);
  const [totalProjects, setTotalProjects] = useState(0);
  const [toastMessage, setToastMessage] = useState("");
  const [toastType, setToastType] = useState("info");
  const [isGridView, setIsGridView] = useState(true); // State to toggle between grid and list views
  const { updateLocalState, localState } = useLocalState();
  const [isAudioPlaying, setIsAudioPlaying] = useState(false);
  const [loading, setLoading] = useState(false); // State to track loading status
  const [offset, setOffset] = useState(0); // Offset state to keep track of project batches
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [pageWidth, setPageWidth] = useState(window.innerWidth);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState("Date edited");
  const dropdownRef = useRef(null);

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const options = [
    "Anytime",
    "Today",
    "Yesterday",
    "Last 30 days",
    "Last 90 days",
    "Last year",
  ];

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setIsOpen(false);
  };

  const bufferToDataURL = (buffer, mimeType) => {
    const binary = Array.from(new Uint8Array(buffer))
      .map((b) => String.fromCharCode(b))
      .join("");
    const base64 = btoa(binary);
    return `data:${mimeType};base64,${base64}`;
  };

  useEffect(() => {
    const handleResize = () => {
      setPageWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    localState.isAudioPlaying
      ? setIsAudioPlaying(true)
      : setIsAudioPlaying(false);
  }, [localState.isAudioPlaying]);

  // Fetch total number of projects on component mount
  useEffect(() => {
    const fetchTotalProjects = async () => {
      try {
        // if (localState.cachedProjects?.length) {
        //   // If totalProjects exists in local state, use it
        //   setTotalProjects(localState.cachedProjects.length);
        //   return;
        // }

        const totalData = await apiService.handleProtectedRoute(
          "fetchProjectsCount",
          {}
        );
        setTotalProjects(parseInt(totalData, 10)); // Ensure total is an integer
        // console.log("Total Projects: ", totalData); // Debugging: Check total projects
      } catch (error) {
        console.error("Error fetching total projects count:", error);
        setToastMessage("Failed to load total projects count");
        setToastType("error");
      }
    };

    fetchTotalProjects();
  }, [projects.length, totalProjects, loading, localState.newSongGenerated]);

  // Fetch projects and their latest AI responses in batches
  const fetchProjects = async () => {
    if (loading || projects.length >= totalProjects) return; // Prevent multiple simultaneous fetches and stop if all projects are loaded
    // console.log("Fetching projects with offset:", offset); // Debugging: Check offset
    try {
      setLoading(true);
      const projectData = await apiService.handleProtectedRoute(
        "fetchProjectsWithLastAiResponse",
        { limit: totalProjects, offset: 0 }
      );

      // console.log("Project Data:", projectData);

      // Process the fetched data and store it in local state
      const projectsWithAiResponses = projectData.map((project) => {
        const projectDetails = {
          ...project,
          imageSrc: project.cover_image_name
            ? `/project-images/${project.cover_image_name}`
            : "/project-images/Album_Art_6.webp", // Fallback image

          audioSrc:
            project.response_id !== null
              ? `${process.env.REACT_APP_SERVER_ENDPOINT}/api/stream-audio/${project.response_id}`
              : null,
          project_description:
            project.music_description?.trim() || "No description available",
        };

        // Store the AI response in local state
        updateLocalState(
          `project_${project.project_id}_aiResponse`,
          projectDetails
        );

        return projectDetails;
      });

      // console.log("Fetched Projects: ", projectsWithAiResponses); // Debugging: Check fetched projects
      setProjects((prev) => {
        const existingProjectIds = new Set(prev.map((p) => p.project_id));
        const newProjects = projectsWithAiResponses.filter(
          (p) => !existingProjectIds.has(p.project_id)
        );
        const updatedProjects = [...prev, ...newProjects];

        // Cache the projects in localState
        updateLocalState("cachedProjects", updatedProjects);

        return updatedProjects;
      });

      // Reset the flag after successful fetch
      updateLocalState("projectNameSetByUser", false);

      // setOffset((prev) => prev + LIMIT); // Update offset for the next fetch
    } catch (error) {
      console.error("Error fetching projects:", error);
      setToastMessage("Failed to load projects");
      setToastType("error");
    } finally {
      setLoading(false);
    }
  };

  // Continuously fetch projects until all are loaded
  useEffect(() => {
    if (
      (!loading && projects.length <= totalProjects) ||
      localState.projectNameSetByUser
    ) {
      if (
        localState.cachedProjects?.length === totalProjects &&
        !localState.newSongGenerated &&
        !localState.projectNameSetByUser
      ) {
        setProjects(localState.cachedProjects);
        return;
      }

      fetchProjects(); // Fetch next batch of projects if more are available
    }
  }, [
    projects.length,
    totalProjects,
    loading,
    localState.newSongGenerated,
    localState.cachedProjects,
    localState.projectNameSetByUser,
  ]);
  // Run effect whenever projects or totalProjects change, or loading state changes

  // Function to handle project deletion and update state
  const handleDeleteProject = (deletedProjectId) => {
    setToastMessage("Project deleted successfully.");
    setToastType("success");
    setProjects((prevProjects) => {
      const updatedProjects = prevProjects.filter(
        (project) => project.project_id !== deletedProjectId
      );
      // Update the cached projects in localState
      updateLocalState("cachedProjects", updatedProjects);
      return updatedProjects;
    });

    setProjects((prevProjects) => {
      const updatedProjects = prevProjects.filter(
        (project) => project.project_id !== deletedProjectId
      );
      return updatedProjects;
    });
    // Update the total projects count
    setTotalProjects((prevTotal) => prevTotal - 1);
  };

  const filterProjectsByDate = (projects, selectedOption) => {
    const now = new Date();

    // Helper functions to get start and end of a day
    const getStartOfDay = (date) =>
      new Date(date.getFullYear(), date.getMonth(), date.getDate());
    const getEndOfDay = (date) =>
      new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
        23,
        59,
        59,
        999
      );

    const getStartDate = () => {
      switch (selectedOption) {
        case "Today":
          return {
            startDate: getStartOfDay(now),
            endDate: getEndOfDay(now),
          };
        case "Yesterday":
          const yesterday = new Date(now);
          yesterday.setDate(yesterday.getDate() - 1);
          return {
            startDate: getStartOfDay(yesterday),
            endDate: getEndOfDay(yesterday),
          };
        case "Last 30 days":
          const thirtyDaysAgo = new Date(now);
          thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);
          return {
            startDate: thirtyDaysAgo,
            endDate: now,
          };
        case "Last 90 days":
          const ninetyDaysAgo = new Date(now);
          ninetyDaysAgo.setDate(ninetyDaysAgo.getDate() - 90);
          return {
            startDate: ninetyDaysAgo,
            endDate: now,
          };
        case "Last year":
          const lastYear = new Date(now);
          lastYear.setFullYear(lastYear.getFullYear() - 1);
          return {
            startDate: lastYear,
            endDate: now,
          };
        default:
          return null;
      }
    };

    const dateRange = getStartDate();
    if (!dateRange || selectedOption === "Anytime") {
      // Sort projects by last_updated date
      return projects.sort(
        (a, b) => new Date(b.last_updated) - new Date(a.last_updated)
      );
    }

    const { startDate, endDate } = dateRange;

    // Filter and sort projects
    return projects
      .filter((project) => {
        const projectDate = new Date(project.last_updated);
        return projectDate >= startDate && projectDate <= endDate;
      })
      .sort((a, b) => new Date(b.last_updated) - new Date(a.last_updated));
  };

  // Update the projects display section:
  const filteredProjects =
    options.includes(selectedOption) && selectedOption !== "Date edited"
      ? filterProjectsByDate(projects, selectedOption)
      : projects;

  const isMobile = windowWidth < 768; // Adjust this breakpoint as needed

  return (
    <div className="projects-page">
      {toastMessage && (
        <Toast
          type={toastType}
          message={toastMessage}
          onClose={() => setToastMessage("")}
        />
      )}
      <div className="hidden md:block relative mx-4 mt-4 mb-3 w-auto h-[9rem] overflow-hidden">
        {/* Pseudo-element for rounded border */}
        <div className="absolute inset-0 rounded-t-lg pointer-events-none [background:linear-gradient(0deg,rgba(0,0,0,0.30)_0%,rgba(0,0,0,0.30)_100%),linear-gradient(135deg,#FC10F2_0%,#3078E4_100%)] z-10"></div>

        {/* Folder SVG in the center with lower z-index */}
        <div className="absolute -bottom-[9rem] left-[35%] flex items-center justify-center z-10 opacity-70">
          <img
            src="/folder.svg"
            alt="Folder Icon"
            className="w-[19rem] h-[24rem]"
          />
        </div>

        {/* right circles */}
        <div className="absolute -top-[23rem] -right-[26rem] w-[45rem] h-[45rem] bg-[#E181FF] opacity-[0.15] bg-blend-hard-light mix-blend-hard-light rounded-full animate-slow-move-reverse z-20" />
        <div className="absolute -bottom-[35rem] -right-[10rem] w-[45rem] h-[45rem] bg-[#E181FF] opacity-[0.15] bg-blend-hard-light mix-blend-hard-light rounded-full animate-slow-move-reverse z-20" />

        {/* left circles */}
        <div className="absolute -bottom-80 -left-[40rem] w-[56rem] h-[56rem] bg-[#E181FF] opacity-[0.15] bg-blend-hard-light mix-blend-hard-light rounded-full animate-slow-move z-20" />
        <div className="absolute -bottom-[42rem] -left-[10em] w-[48rem] h-[48rem] bg-[#E181FF] opacity-[0.15] bg-blend-hard-light mix-blend-hard-light rounded-full animate-slow-move z-20" />

        {/* Bottom-left title */}
        <h1 className="absolute bottom-6 left-5 text-4xl font-normal text-white z-20">
          Projects
        </h1>
      </div>

      <div className="hidden md:flex flex-row justify-between pb-3 px-4">
        <div className="relative inline-block text-left" ref={dropdownRef}>
          {/* Button to toggle dropdown */}
          <button
            onClick={() => setIsOpen(!isOpen)}
            className="[background:var(--Color-Opacity-White-5,rgba(255,255,255,0.05))] text-[#878188] text-sm hover:text-white duration-200 px-3 h-9 rounded-lg flex items-center space-x-2"
          >
            <span>{selectedOption}</span>
            {isOpen ? (
              <ChevronUp className="w-4 h-4 text-[#878188]" />
            ) : (
              <ChevronDown className="w-4 h-4 text-[#878188]" />
            )}
          </button>

          {/* Dropdown options */}
          {isOpen && (
            <div className="absolute mt-2 w-40 bg-[#100F10] border border-[#2C2A2C] rounded-lg shadow-lg z-20">
              <ul className="my-1">
                {options.map((option) => (
                  <li
                    key={option}
                    onClick={() => handleOptionClick(option)}
                    className="cursor-pointer px-2 py-2 text-sm text-[#878188] hover:text-white hover:hover:bg-zinc-900 mx-1 rounded-md"
                  >
                    {option}
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
        <div className="flex justify-end bg-[#1D1B1D] rounded-full gap-2.5 px-3">
          <button
            onClick={() => setIsGridView(true)}
            className={`transition duration-300 ${
              isGridView ? " text-white" : "text-[#878188]"
            }`}
            id="projects-grid-view-button"
          >
            <Rectangles4 />
          </button>
          <button
            onClick={() => setIsGridView(false)}
            className={`transition duration-300 ${
              !isGridView ? " text-white" : "text-[#878188]"
            }`}
            id="projects-list-view-button"
          >
            <Bars />
          </button>
        </div>
      </div>
      <div className="w-full border-t border-t-[#2C2A2C]"></div>

      {/* Toggle buttons for Grid and List View */}

      {/* Render Grid View or List View based on state */}
      {loading && projects.length === 0 ? (
        isGridView && !isMobile ? (
          <ProjectCardLoader itemCount={3} />
        ) : (
          <LoadingSongs itemCount={3} />
        ) // Show loaders while loading
      ) : isGridView && !isMobile ? (
        <div
          className={`project-grid-container max-h-[calc(100vh-${
            isAudioPlaying ? `320px` : `255px`
          })] overflow-y-auto p-4 scrollable-content`}
        >
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4">
            {filteredProjects.map((project, index) => (
              <ProjectCard
                key={index}
                imageSrc={project.imageSrc}
                projectName={project.project_name}
                projectDescription={project.project_description}
                audioSrc={project.audioSrc}
                songTitle={project.song_title}
                projectId={project.project_id}
                responseId={project.response_id}
                onDelete={handleDeleteProject}
              />
            ))}
          </div>
          {loading && <p>Loading more projects...</p>}
        </div>
      ) : (
        <div
          className={`max-h-[calc(100vh-${
            isAudioPlaying && !isMobile
              ? `320px`
              : isMobile && isAudioPlaying
              ? `225px`
              : isMobile && !isAudioPlaying
              ? `165px`
              : !isMobile && !isAudioPlaying && `255px`
          })] min-h-[300px] overflow-y-auto scrollable-content`}
        >
          {filteredProjects.map((project) => (
            <ProjectListView
              key={project.project_id}
              imageSrc={project.imageSrc}
              projectName={project.project_name}
              projectDescription={project.project_description}
              audioSrc={project.audioSrc}
              songTitle={project.song_title}
              projectId={project.project_id}
              responseId={project.response_id}
              onDelete={handleDeleteProject}
              songDuration={project.music_duration}
            />
          ))}
          {loading && <p>Loading more projects...</p>}
        </div>
      )}
    </div>
  );
};

export default ProjectGrid;
