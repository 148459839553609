import React, { useState, useEffect } from "react";

function AudioBars() {
  const [heights, setHeights] = useState([4, 4, 4, 4]);

  useEffect(() => {
    const interval = setInterval(() => {
      setHeights([
        Math.floor(Math.random() * 12) + 4,
        Math.floor(Math.random() * 12) + 4,
        Math.floor(Math.random() * 12) + 4,
        Math.floor(Math.random() * 12) + 4,
      ]);
    }, 200);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="audio-bars">
      {heights.map((height, index) => (
        <div
          key={index}
          className={`bar bar${index + 1}`}
          style={{
            height: `${height}px`,
            top: `calc(50% - ${height / 2}px)`,
          }}
        ></div>
      ))}
    </div>
  );
}

export default AudioBars;
