import React, { useState, useEffect } from "react";
import { PencilToLine, Check, Xmark, PencilToSquare } from "@gravity-ui/icons";
import apiService from "../../api/apiService";
import Toast from "../generic_components/Toast";
import { functionMapper } from "../../utils/functionMapper";
import { getCookie } from "../../utils/cookieUtils";
import { useLocalState } from "../../contexts/LocalStateProvider";

const EditableField = ({
  initialValue,
  inputClassName,
  saveButtonClassName,
  cancelButtonClassName,
  iconClassName,
  icon,
  type,
  onSave,
  requestData,
  name,
  id,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [value, setValue] = useState(initialValue);
  const [prevValue, setPrevValue] = useState(initialValue);
  const [imageSrc, setImageSrc] = useState(initialValue);
  const [toastMessage, setToastMessage] = useState("");
  const [toastType, setToastType] = useState("info");
  const { updateLocalState } = useLocalState();
  const [pageWidth, setPageWidth] = useState(window.innerWidth);

  const IconComponent = icon ? PencilToSquare : null;

  useEffect(() => {
    if (type === "file" && prevValue && prevValue.type === "Buffer") {
      const binaryData = new Uint8Array(prevValue.data);
      const blob = new Blob([binaryData], { type: "image/jpeg" }); // Change the type accordingly
      const url = URL.createObjectURL(blob);
      setImageSrc(url);
    } else {
      setImageSrc(prevValue);
      setValue(prevValue);
    }
  }, [prevValue, type]);

  useEffect(() => {
    const handleResize = () => {
      setPageWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleSave = async () => {
    // Validate and truncate the text if needed
    const { isValid, message, text } =
      functionMapper.validateAndTruncateText(value);

    if (isValid) {
      if (value === prevValue) {
        // No changes, so just exit
        setIsEditing(false);
        return;
      }

      setPrevValue(value);

      setIsEditing(false);

      // Prepare payload for API request
      const formData = { [name]: text };
      const mergedPayload = { ...requestData.payload, ...formData };

      const user_id = getCookie("user_id");

      if (user_id) mergedPayload.user_id = user_id;

      try {
        const result = await apiService.sendRequest(
          requestData.taskName,
          mergedPayload
        );
        if (result.success) {
          setToastMessage(result.success);
          setToastType("success");
          if (onSave) onSave(value); // Call onSave with the new value

          if (requestData?.taskName === "updateFullName") {
            updateLocalState("userNameAndAvatar", null);
          }
          updateLocalState("userProfileData", null);
        } else {
          setToastMessage(result.error || "An error occurred");
          setToastType("error");
        }
      } catch (error) {
        setToastMessage("An error occurred while saving");
        setToastType("error");
      }
    } else {
      setToastMessage(message);
      setToastType("info");
    }
  };

  const handleCancel = () => {
    setIsEditing(false);
    setValue(prevValue);
    setImageSrc(prevValue);
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setImageSrc(e.target.result);
      };
      reader.readAsDataURL(file);

      // Prepare payload for API request
      const formData = new FormData();
      formData.append("avatar", file);
      formData.append("user_id", getCookie("user_id")); // Assuming you have a function to get cookies

      try {
        const result = await apiService.updateAvatar(formData);
        if (result.success) {
          setToastMessage(result.success);
          setToastType("success");
          if (onSave) onSave(e.target.result); // Call onSave with the new image source
          if (requestData?.taskName === "updateAvatar") {
            updateLocalState("userNameAndAvatar", null);
            updateLocalState("userAvatar", null);
            updateLocalState("userProfileData", null);
          }
        } else {
          setToastMessage(result.error || "An error occurred");
          setToastType("error");
        }
      } catch (error) {
        setToastMessage("An error occurred while saving");
        setToastType("error");
      }
    }
  };

  return (
    <div className="flex items-center">
      {type === "file" ? (
        <>
          <img
            src={imageSrc ?? "./logo.png"}
            alt="Avatar"
            className="w-12 h-12 object-cover rounded-full bg-gray-700"
          />
          <input
            type="file"
            className="hidden"
            id={`file-input-${name}`} // Unique ID for each file input
            onChange={handleFileChange}
            accept="image/png, image/jpeg, image/webp"
          />
          <label htmlFor={`file-input-${name}`} className={iconClassName}>
            {IconComponent && <IconComponent />}
          </label>
        </>
      ) : isEditing ? (
        <>
          {pageWidth <= 768 ? (
            <>
              <input
                id={id}
                type="text"
                className={inputClassName}
                value={value}
                onChange={(e) => setValue(e.target.value)}
              />
              {/* <button className={cancelButtonClassName} onClick={handleCancel}>
                <Xmark />
              </button> */}
              <button className={saveButtonClassName} onClick={handleSave}>
                <Check />
              </button>
            </>
          ) : (
            <>
              <input
                id={id}
                type="text"
                className={inputClassName}
                value={value}
                onChange={(e) => setValue(e.target.value)}
              />
              <button className={cancelButtonClassName} onClick={handleCancel}>
                Cancel
              </button>
              <button className={saveButtonClassName} onClick={handleSave}>
                Save
              </button>
            </>
          )}
        </>
      ) : (
        <>
          <span className="text-sm text-white font-extralight">{value}</span>
          {IconComponent && (
            <button
              className={iconClassName}
              onClick={() => setIsEditing(true)}
            >
              <IconComponent />
            </button>
          )}
        </>
      )}
      {toastMessage && (
        <Toast
          type={toastType}
          message={toastMessage}
          onClose={() => setToastMessage("")}
        />
      )}
    </div>
  );
};

export default EditableField;
