import React, { useState } from "react";
import { Check, CircleExclamation } from "@gravity-ui/icons";
import apiService from "../../api/apiService";
import { getCookie } from "../../utils/cookieUtils";

const FeedbackContent = ({ onClose, onSubmit, responseId }) => {
  const [selectedOption, setSelectedOption] = useState(null);
  const [feedback, setFeedback] = useState("");

  const options = [
    { label: "Inaccurate", value: "inaccurate" },
    { label: "Offensive content", value: "offensive" },
    { label: "Copyright violation", value: "copyright" },
    { label: "Not helpful", value: "not_helpful" },
  ];

  const handleOptionClick = (value) => {
    setSelectedOption(value === selectedOption ? null : value);
  };

  const handleSubmitFeedback = async () => {
    const userId = getCookie("user_id");

    if (!userId || !responseId) return;

    const payload = {
      response_id: responseId,
      user_id: Number(userId),
      dislike_type: selectedOption || null, // Default to null if no option selected
      dislike_description: feedback?.trim() || null, // Default to null if no feedback provided
    };

    try {
      const result = await apiService.sendRequest(
        "updateDislikeFeedback",
        payload
      );

      if (result.success) {
        onSubmit(); // Close the modal or any other post-submit action
      } else {
        console.error("Failed to submit feedback:", result.error);
      }
    } catch (error) {
      console.error("Error submitting feedback:", error);
    }
  };

  const isSubmitEnabled = selectedOption !== null || feedback.trim() !== "";

  return (
    <div className="text-left bg-[#1d1b1d] border border-solid border-[#3c393c] rounded-lg w-full">
      <h2 className="px-3 py-3 text-lg font-normal text-white">
        Help us Improve
      </h2>
      <div className="w-full border-t border-t-[#2C2A2C]"></div>

      <div className="py-3 px-3">
        <p className="text-sm text-white font-normal mb-2">Select one option</p>
        <div
          className="w-full grid lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-2 gird-cols-1 gap-2 mb-1"
          id={"feedback-options"}
        >
          {options.map((option) => (
            <button
              key={option.value}
              className={`flex items-center justify-between font-medium w-full px-2.5 h-9 rounded-lg text-white text-sm ${
                selectedOption === option.value
                  ? "[background:var(--Color-Opacity-Primary-10,rgba(186,24,236,0.10))] border border-[color:var(--Color-Primary-50,#BA18EC)]"
                  : "bg-transparent border border-[#2C2A2C]"
              }`}
              onClick={() => handleOptionClick(option.value)}
            >
              <div className="flex items-center">
                <CircleExclamation className="mr-2" />
                <span>{option.label}</span>
              </div>
              {selectedOption === option.value && (
                <span className="ml-2">
                  <Check />
                </span>
              )}
            </button>
          ))}
        </div>
        <div className="pt-4">
          <label className="text-sm font-light text-white block mb-2">
            How can we improve?
          </label>
          <textarea
            id="feedback-textarea"
            className="w-full h-24 p-2 px-3 border border-[#878188] font-light rounded-xl bg-transparent text-white text-sm placeholder-[#878188]"
            placeholder="Please enter your feedback here..."
            value={feedback}
            onChange={(e) => setFeedback(e.target.value)}
          />
        </div>
      </div>
      <div className="w-full border-t border-t-[#2C2A2C]"></div>
      <div className="py-3 px-3">
        <div className="flex justify-end">
          <button
            id="cancel-feedback-button"
            className="text-sm text-[#878188] hover:bg-[rgba(255,255,255,0.05)] px-3 h-8 mr-2 hover:text-white rounded-full"
            onClick={onClose}
          >
            Cancel
          </button>
          <button
            id="submit-feedback-button"
            className={`text-sm px-3 h-8 rounded-full ${
              isSubmitEnabled
                ? "bg-white text-black"
                : "bg-[#4E4A4F] text-[#878188] cursor-not-allowed"
            }`}
            disabled={!isSubmitEnabled}
            onClick={handleSubmitFeedback}
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default FeedbackContent;
