import React, { useState, useRef, useEffect, memo, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useAudioPlayer } from "../../contexts/AudioPlayerContext";
import Modal from "../modal/Modal";
import InputWithButton from "../generic_components/InputWithButton";
import {
  ArrowUpRightFromSquare,
  TrashBin,
  Ellipsis,
  PlayFill,
  PauseFill,
  ArrowShapeDownToLine,
} from "@gravity-ui/icons";
import apiService from "../../api/apiService";
import Toast from "../generic_components/Toast";

const ProjectCard = memo(
  ({
    imageSrc,
    projectName,
    projectDescription,
    audioSrc,
    songTitle,
    projectId,
    responseId,
    onDelete, // Receive the onDelete function from parent
  }) => {
    const [showDropdown, setShowDropdown] = useState(false);
    const dropdownRef = useRef(null);
    const dropdownButtonRef = useRef(null);
    const dropdownContainerRef = useRef(null);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [toastMessage, setToastMessage] = useState("");
    const [toastType, setToastType] = useState("info");
    const navigate = useNavigate();
    const {
      isPlaying,
      playAudio,
      pauseAudio,
      resumeAudio,
      audioSrc: currentAudioSrc,
      setAiResponseId,
    } = useAudioPlayer();

    const handleDownloadProjectSong = async () => {
      if (!audioSrc) {
        setToastMessage("No audio file to download.");
        setToastType("info");
      }

      gtag("event", "download_music", {
        event_category: "Music Actions",
        event_label: "Download Button",
        value: 1,
      });

      if (!responseId) {
        return;
      }

      const taskName = "fetchCompleteAIResponseAudio";

      const requestPayload = { ai_response_id: responseId };

      const response = await apiService.sendRequest(taskName, requestPayload);

      const { audioFile } = response;

      if (response.success) {
        const blob = new Blob([new Uint8Array(audioFile.data)], {
          type: "audio/wav",
        });
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = songTitle || "audio.wav";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        response?.message
          ? setToastMessage(response?.message)
          : setToastMessage("Failed to fetch the audio file for download.");
      }
    };

    const handlePlayPause = useCallback(
      (event) => {
        setAiResponseId(responseId);
        // Prevent playing audio if the click is on the dropdown button or any of its options
        if (
          (dropdownButtonRef.current &&
            dropdownButtonRef.current.contains(event.target)) ||
          (dropdownContainerRef.current &&
            dropdownContainerRef.current.contains(event.target))
        ) {
          return;
        }

        if (!audioSrc) {
          setToastMessage("No audio available for this project.");
          setToastType("info");
          return;
        }

        if (isPlaying && currentAudioSrc === audioSrc) {
          pauseAudio();
        } else if (currentAudioSrc === audioSrc) {
          resumeAudio();
        } else {
          playAudio(audioSrc, songTitle, projectDescription, imageSrc);
        }
      },
      [
        audioSrc,
        currentAudioSrc,
        isPlaying,
        pauseAudio,
        playAudio,
        projectName,
        projectDescription,
        imageSrc,
      ]
    );

    const handleDropdownToggle = () => {
      setShowDropdown(!showDropdown);
    };

    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
    };

    useEffect(() => {
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, []);

    const handleGoToProject = () => {
      navigate(`/chatPage?projectId=${projectId}`);
    };

    const handleDeleteProject = () => {
      setShowDeleteModal(true);
    };

    const closeModal = () => {
      setShowDeleteModal(false);
    };

    const confirmDeleteProject = async () => {
      try {
        const result = await apiService.sendRequest("deleteProject", {
          project_id: projectId,
        });

        if (result.success) {
          // console.log("Project deleted successfully", projectId);

          onDelete(projectId); // Call the onDelete function passed from parent to update the state
        } else {
          setToastMessage(
            result.error || "An error occurred while deleting the project."
          );
          setToastType("error");
        }
      } catch (error) {
        setToastMessage("An error occurred while deleting the project.");
        setToastType("error");
      } finally {
        setShowDeleteModal(false);
      }
    };

    return (
      <>
        <div
          id="project-card"
          onClick={handleGoToProject}
          className="relative flex flex-col cursor-pointer gap-2 px-2 pt-2 pb-3 grow bg-[#1d1b1d] transform duration-500 hover:bg-[#2c2a2c] rounded-xl group"
        >
          <div
            className="relative self-stretch h-48 p-2 overflow-hidden"
            ref={dropdownRef}
          >
            <img
              src={imageSrc}
              alt={projectName}
              className="absolute inset-0 w-full h-full object-cover rounded-lg"
            />
            <button
              id="project-play-pause-button"
              onClick={(e) => {
                e.stopPropagation();
                handlePlayPause(e);
              }}
              className={`absolute bottom-2 left-2 p-3 flex justify-center items-center gap-1 bg-white text-[#100f10] rounded-full transform ${
                showDropdown || (isPlaying && currentAudioSrc === audioSrc)
                  ? "translate-y-0"
                  : "transform translate-y-[150%] group-hover:translate-y-0"
              } transition-transform duration-300 ease-in-out`}
            >
              {isPlaying && currentAudioSrc === audioSrc ? (
                <PauseFill />
              ) : (
                <PlayFill />
              )}
            </button>
            <button
              id="project-options-button"
              onClick={(e) => {
                e.stopPropagation();
                handleDropdownToggle();
              }}
              className={`absolute top-2 right-2 p-2 flex justify-center items-center gap-2 text-white bg-[#2c2a2c] rounded-full transform ${
                showDropdown
                  ? "translate-y-0"
                  : "-translate-y-[150%] group-hover:translate-y-0"
              } transition-transform duration-300 ease-in-out`}
              ref={dropdownButtonRef}
            >
              <Ellipsis className="" />
            </button>

            {showDropdown && (
              <div
                id="project-options-dropdown"
                ref={dropdownContainerRef}
                className="absolute top-10 right-2 text-center font-normal text-sm text-[#878188] bg-[#100f10] p-1 rounded-lg border border-solid border-[#2c2a2c] w-36"
              >
                <button
                  id="project-go-to-button"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleGoToProject();
                  }}
                  className="flex items-center gap-2 h-8 px-2 w-full hover:text-white rounded-lg hover:bg-[#1d1b1d]"
                >
                  <ArrowUpRightFromSquare className="w-4 h-4" /> Go to project
                </button>
                <button
                  id="project-download-button"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleDownloadProjectSong();
                  }}
                  className="flex items-center gap-2 h-8 px-2 w-full hover:text-white rounded-lg hover:bg-[#1d1b1d]"
                >
                  <ArrowShapeDownToLine className="w-4 h-4" /> Download
                </button>
                <button
                  id="project-delete-button"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleDeleteProject();
                  }}
                  className="flex items-center gap-2 h-8 px-2 w-full hover:text-white rounded-lg hover:bg-[#1d1b1d]"
                >
                  <TrashBin className="w-4 h-4" /> Delete project
                </button>
              </div>
            )}
          </div>
          <div className="flex flex-col self-stretch px-1">
            <span className="font-normal text-base text-white">
              {projectName}
            </span>
            <span className="font-extralight text-sm text-[#c5c3c6] truncate">
              {projectDescription}
            </span>
          </div>
        </div>
        {showDeleteModal && (
          <Modal closeModal={closeModal}>
            <div className="text-white w-full pb-3 bg-[#1d1b1d] border border-solid border-[#3c393c] rounded-lg">
              <h3 className="text-left text-base p-3" id="delete-modal-title">
                Delete Project
              </h3>
              <div className="w-full border-t border-t-[#2d2a2d]"></div>
              <InputWithButton
                formClassName="space-y-4 w-full px-3"
                inputFields={[
                  {
                    type: "info",
                    content: ["Are you sure you want to delete this project?"],
                    subContent:
                      "This will delete all the generated music and comments. Do you want to proceed?",
                    className:
                      "text-white text-left text-sm font-extralight mb-4 mt-3",
                  },
                ]}
                buttonClassName="px-3 mr-3 h-8 text-sm bg-red-600 hover:bg-[#BB2D2D] text-white rounded-md"
                buttonText="Delete"
                onButtonClick={confirmDeleteProject} // Call confirmDeleteProject when delete is clicked
                cancelButtonClassName="px-3 h-8 text-sm rounded-md hover:[background:var(--Color-Opacity-White-5,rgba(255,255,255,0.05))] text-white"
                cancelButtonText="Cancel"
                onCancelClick={closeModal}
                closeModal={closeModal}
                cancelButtonId={"delete-project-cancel-button"}
                actionButtonId={"delete-project-confirm-button"}
                requestData={{
                  taskName: "deleteProject",
                  payload: {
                    project_id: projectId,
                  },
                }}
              />
            </div>
          </Modal>
        )}
        {toastMessage && (
          <Toast
            type={toastType}
            message={toastMessage}
            onClose={() => setToastMessage("")}
          />
        )}
      </>
    );
  }
);

export default ProjectCard;
