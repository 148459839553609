import React, { useState, useEffect } from "react";
import { Range, getTrackBackground } from "react-range";

const DurationDropdown = ({ onApply, onReset }) => {
  const [range, setRange] = useState([0, 300]);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    // Update local state when the duration is reset externally
    setRange([0, 300]);
  }, [onReset]);

  const handleReset = () => {
    setRange([0, 300]);
    if (onReset) onReset();
  };
  const toggleDropdown = () => setIsOpen(!isOpen);

  return (
    <div className="pt-6 pb-4 px-4 rounded-lg border border-[#1d1b1d] z-20">
      <Range
        step={1}
        min={0}
        max={300}
        values={range}
        onChange={(values) => setRange(values)}
        renderTrack={({ props, children }) => (
          <div
            {...props}
            style={{
              ...props.style,
              height: "4px",
              width: "100%",
              borderRadius: "2px",
              background: getTrackBackground({
                values: range,
                colors: ["#686369", "#ffff", "#686369"],
                min: 0,
                max: 300,
              }),
            }}
          >
            {children}
          </div>
        )}
        renderThumb={({ props }) => (
          <div
            {...props}
            style={{
              ...props.style,
              height: "12px",
              width: "12px",
              borderRadius: "100%",
              backgroundColor: "#FFF",
            }}
          />
        )}
      />
      <div className="flex justify-between mt-4 gap-2">
        <div className="flex flex-col items-start w-full">
          <span className="text-sm text-white font-extralight">From</span>
          <span className="flex mt-1 border rounded-full h-9 px-3 items-center text-left w-full text-sm font-extralight">
            {Math.floor(range[0] / 60)}:
            {range[0] % 60 < 10 ? `0${range[0] % 60}` : range[0] % 60}
          </span>
        </div>
        <div className="flex flex-col items-start w-full">
          <span className="text-sm text-white font-extralight">Up to</span>
          <span className="flex mt-1 border rounded-full h-9 px-3 items-center text-left w-full text-sm font-extralight">
            {Math.floor(range[1] / 60)}:
            {range[1] % 60 < 10 ? `0${range[1] % 60}` : range[1] % 60}
          </span>
        </div>
      </div>
      <div className="flex justify-between mt-4">
        <button
          onClick={handleReset}
          className="hover:bg-[rgba(255,255,255,0.05)] text-[#878188] hover:text-white h-8 px-3 text-sm font-normal rounded-full"
        >
          Reset
        </button>
        <button
          onClick={() => onApply(range)} // Pass the selected range when the "Apply" button is clicked
          className="bg-white hover:bg-[#E0DFE1] h-8 px-3 text-sm font-normal rounded-full text-[#100F10]"
        >
          Apply
        </button>
      </div>
    </div>
  );
};

export default DurationDropdown;
