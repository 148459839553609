import React from "react";
import DOMPurify from "dompurify";

const TermsOfUse = () => {
  const rawHtmlContent = (
    <>
      <p>
        <span style={{ backgroundColor: "transparent" }}>
          These Terms of Use ("Terms") are between you ("you", “your”, "User",
          “Subscriber”) and&nbsp; Wubble.Ai Private Ltd ("We", "Wubble",
          “Company”, “our”, “us”). If you represent a legal&nbsp; entity then
          “you”, “your”, “User” or “Subscriber” also means such a legal entity.
        </span>
      </p>
      <p>
        <br />
      </p>
      <p>
        <span style={{ backgroundColor: "transparent" }}>
          The Terms establish the terms and conditions under which you may
          access and use the Site or&nbsp; Platform and Services (terms defined)
          offered, provided and made available to you by Wubble&nbsp; and/or its
          affiliates. By accessing and/or using the Site or Services, you accept
          these Terms,&nbsp; the Privacy Policy, Cookies Policy and Subscriber
          License (which applies to the Selected&nbsp; Tracks you licensed), all
          of which shall be read together and you agree to be bound by
          them.&nbsp; Terms, Privacy Policy, Cookies Policy and Subscriber
          License are collectively referred to as&nbsp; “Agreement”. Therefore,
          it is your obligation to read the Agreement collectively and
          carefully,&nbsp; and make sure you agree with them before any access
          or use of the Site or Services. These&nbsp; Terms, Subscriber License,
          Privacy Policy and Cookies Policy apply for as long as you
          access&nbsp; the Site or use the Services.&nbsp;&nbsp;
        </span>
      </p>
      <p>
        <br />
      </p>
      <p>
        <span style={{ backgroundColor: "transparent" }}>
          Capitalized terms used but not defined shall have the respective
          meanings given to them in the&nbsp; Subscriber License, Privacy
          Policy, Cookies Policy.&nbsp;&nbsp;
        </span>
      </p>
      <p>
        <br />
      </p>
      <h2 className="text-2xl pt-6 pb-4">
        <h1 style={{ backgroundColor: "transparent" }}>
          1. Company Information&nbsp;&nbsp;
        </h1>
      </h2>
      <p>
        <span style={{ backgroundColor: "transparent" }}>
          Wubble is established under the laws of Singapore, with its registered
          office at 32 PEKIN&nbsp; STREET #05-01 SINGAPORE (048762).&nbsp;&nbsp;
        </span>
      </p>
      <h2 className="text-2xl pt-6 pb-4">
        <span style={{ backgroundColor: "transparent" }}>
          2. Service Description (“Services” also defined)&nbsp;&nbsp;
        </span>
      </h2>
      <p>
        <span style={{ backgroundColor: "transparent" }}>
          Wubble is a music platform supported by artificial intelligence,
          dedicated to creating&nbsp; customised music and audio experiences.
          The Site or Platform receives, processes input data&nbsp; that you
          provide, which may include videos, audio, images, information, text,
          and other&nbsp; materials and media (collectively referred to as
          "Submissions"). It creates, produces and&nbsp; generates Tracks
          (defined) based on these Submissions. The Service may also include a
          public&nbsp; forum where Content (defined) can be shared productively
          with other users.&nbsp;&nbsp;
        </span>
      </p>
      <h2 className="text-2xl pt-6 pb-4">
        <span style={{ backgroundColor: "transparent" }}>
          3. Your Representations&nbsp;
        </span>
      </h2>
      <p>
        <span style={{ backgroundColor: "transparent" }}>
          You represent and warrant that:&nbsp;&nbsp;
        </span>
      </p>
      <ul className="list-disc list-outside ml-5 mt-3 space-y-1">
        <li>
          <span style={{ backgroundColor: "transparent" }}>
            You are 18 or over years of age and/or your age does not limit you
            from lawfully&nbsp; entering into the Agreement and that you are not
            legally prohibited from entering into&nbsp; the Agreement.
          </span>
        </li>
        <li>
          <span style={{ backgroundColor: "transparent" }}>
            You are authorized to enter into and perform the Agreement for
            yourself or for the&nbsp; entity you represent, the details of which
            were specified upon sign up to the Service&nbsp; whether under a
            Subscription Plan or for Free Credits (defined).
          </span>
        </li>
        <li>
          <span style={{ backgroundColor: "transparent" }}>
            You have not previously been suspended or removed from the
            Service;&nbsp; 3.4. Your registration and your use of the Service is
            in compliance with any and all&nbsp; applicable laws and
            regulations; and&nbsp;&nbsp;
          </span>
        </li>
        <li>
          <span style={{ backgroundColor: "transparent" }}>
            You have read the Agreement, understood the terms stated therein,
            and you hereby&nbsp; agree to engage with the Company under such
            terms.&nbsp;&nbsp;
          </span>
        </li>
      </ul>
      <h2 className="text-2xl pt-6 pb-4">
        <span style={{ backgroundColor: "transparent" }}>
          4. Submissions&nbsp;&nbsp;
        </span>
      </h2>
      <ul className="list-disc list-outside ml-5 space-y-1">
        <li>
          <span style={{ backgroundColor: "transparent" }}>
            You are solely responsible for your use of the Service and all
            Submissions provided to&nbsp; Wubble, whether by uploading,
            emailing, or otherwise sharing with other users. This&nbsp; includes
            all risks associated with Submissions, such as transmission, and
            ensuring the&nbsp; accuracy, quality, legality, and appropriateness
            of the Submissions. It is crucial that&nbsp; you only upload, post,
            publish, or display ("upload") Submissions that you have the&nbsp;
            right to use and provide under these Terms.&nbsp;&nbsp;
          </span>
        </li>
        <li>
          <span style={{ backgroundColor: "transparent" }}>
            Our uses of your Submission include for creating, producing and
            generating Tracks; for training, machine learning, developing,
            fine-tuning, or improving the Service; and&nbsp; in any related
            artificial intelligence or machine learning models.&nbsp;&nbsp;
          </span>
        </li>
        <li>
          <span style={{ backgroundColor: "transparent" }}>
            By uploading any Submission, you represent and warrant
            that:&nbsp;&nbsp;
          </span>
        </li>
        <ul className="list-disc list-outside ml-5 space-y-1">
          <li className="ql-indent-1">
            <span style={{ backgroundColor: "transparent" }}>
              You have, or have obtained, all rights, licenses, consents,
              permissions, power, and/or&nbsp; authority necessary to upload,
              submit and use (and allow us to use) such Submission in&nbsp;
              connection with the Service, throughout the world, in perpetuity
              as specified above;&nbsp;&nbsp;
            </span>
          </li>
          <li className="ql-indent-1">
            <span style={{ backgroundColor: "transparent" }}>
              Your Submissions and their use in connection with the Service and
              as specified above&nbsp; will not violate any applicable laws,
              third-party rights or terms, and conditions&nbsp; associated with
              such Submission;&nbsp;
            </span>
          </li>
          <li className="ql-indent-1">
            <span style={{ backgroundColor: "transparent" }}>
              No additional licenses, permissions, consents, or authorizations
              are required, nor are&nbsp; any payments or royalties due to any
              other person or entity by us (or any third party&nbsp; deriving
              any rights or obligations from us) for using your Submissions in
              connection&nbsp; with the Service and as specified
              above.&nbsp;&nbsp;
            </span>
          </li>
        </ul>
      </ul>
      <h2 className="text-2xl pt-6 pb-4">
        <span style={{ backgroundColor: "transparent" }}>
          5. General Provisions&nbsp;
        </span>
      </h2>
      <ul className="list-disc list-outside ml-5 space-y-1">
        <li>
          <span style={{ backgroundColor: "transparent" }}>
            Registration Obligation: To access the Service, you may need to
            register with Wubble&nbsp; or provide personal information (e.g.,
            name and email address). By registering, you&nbsp; agree to provide
            and maintain accurate and up-to-date information as required by
            the&nbsp; registration form. Your registration data and other
            personal information are protected&nbsp; under our Privacy Policy
            and Cookies Policy.&nbsp;&nbsp;
          </span>
        </li>
        <li>
          <span style={{ backgroundColor: "transparent" }}>
            Member Account, Password, and Security: You are responsible for
            keeping your&nbsp; password and account details confidential for all
            activities under your account. Wubble&nbsp; is not responsible for
            any loss or damage resulting from your failure to comply with&nbsp;
            any security measures. You agree to:&nbsp;&nbsp;
          </span>
        </li>
        <ul className="list-disc list-outside ml-5 space-y-1">
          <li className="ql-indent-1">
            <span style={{ backgroundColor: "transparent" }}>
              log out from your account at the end of each session;&nbsp;&nbsp;
            </span>
          </li>
          <li className="ql-indent-1">
            <span style={{ backgroundColor: "transparent" }}>
              immediately notify Wubble of any unauthorized use of your account
              or any security&nbsp; breach.&nbsp;&nbsp;
            </span>
          </li>
        </ul>
        <li>
          <span style={{ backgroundColor: "transparent" }}>
            Modifications to Service: Wubble reserves the right to modify,
            suspend, or discontinue&nbsp; the Service, or any part of it, at any
            time, with or without notice. You agree that Wubble&nbsp; is not
            liable for any modification, suspension, or discontinuation of the
            Service.&nbsp;&nbsp;
          </span>
        </li>
        <li>
          <span style={{ backgroundColor: "transparent" }}>
            General Practices Regarding Use and Storage: You acknowledge that
            Wubble may&nbsp; establish general practices and limits concerning
            the use of the Service. This includes&nbsp; the maximum duration
            that data or content will be retained and the maximum storage&nbsp;
            space allocated on Wubble's or its third-party service providers'
            servers. Wubble&nbsp; reserves the right to terminate accounts (and
            all associated Submissions) that are&nbsp; inactive for a long
            period. Additionally, Wubble may change these practices and limits
            at any time, at its sole discretion, with or without
            notice.&nbsp;&nbsp;
          </span>
        </li>
        <li>
          <span style={{ backgroundColor: "transparent" }}>
            Team Subscription: In case you purchase a Team Subscription, you
            will be required to&nbsp; provide us with the relevant details for
            the access by all users under such Subscription,&nbsp; and the
            following will apply:&nbsp;
          </span>
        </li>
        <ul className="list-disc list-outside ml-5 space-y-1">
          <li className="ql-indent-1">
            <span style={{ backgroundColor: "transparent" }}>
              Each entity or person purchasing a Team subscription ("Team
              Account") shall select a&nbsp; person to register the Team Account
              ("Admin") which shall act as its authorized&nbsp; representative
              and any decision or action made by the Admin is deemed as a
              decision&nbsp; or action of that entity or person. An Admin
              possesses important privileges and controls&nbsp; over the use of
              the Team Account, including, without limitation: (i) control Admin
              and&nbsp; the Team Account users' use of the Team Account; (ii)
              purchase, upgrade or downgrade&nbsp; the subscription; (iii)
              create, monitor, or modify Team Account users’ actions and&nbsp;
              permissions; (iv) add, substitute, or remove Team Account users
              from the Team&nbsp; Account. An Admin can be replaced only subject
              to the provision of the necessary&nbsp; information/documentation
              to the Company’s support team.&nbsp;&nbsp;
            </span>
          </li>
          <li className="ql-indent-1">
            <span style={{ backgroundColor: "transparent" }}>
              The entity or person purchasing the Team Account is solely liable
              and responsible for&nbsp; understanding and controlling the
              settings of and access to the Team Account, including&nbsp;
              without limitation, the specific rights granted to the Admin. The
              entity or person&nbsp; purchasing the Team Account is responsible
              for the activities of all of its users, even if&nbsp; they are not
              part of its organization or domain. Furthermore, the entity or
              person&nbsp; purchasing the Team Account acknowledges that any
              action taken by the Admin or any&nbsp; Team Account users is
              deemed by Company as an authorized action by it, hence it
              shall&nbsp; have no claim in this regard.&nbsp;&nbsp;
            </span>
          </li>
        </ul>
      </ul>
      <h2 className="text-2xl pt-6 pb-4">
        <span style={{ backgroundColor: "transparent" }}>
          6. Free Credits&nbsp;&nbsp;
        </span>
      </h2>
      <ul className="list-disc list-outside ml-5 space-y-1">
        <li>
          <span style={{ backgroundColor: "transparent" }}>
            We may offer Free Credits for your use of the Service, Free Credits
            are specified&nbsp; number of minutes provided to you by Wubble, at
            no cost to you, solely to be used for&nbsp; generating Tracks on the
            Service. When made available to you by Wubble, your Free&nbsp;
            Credits will be accessible by you starting on the day you sign up
            for them.&nbsp;&nbsp;
          </span>
        </li>
        <li>
          <span style={{ backgroundColor: "transparent" }}>
            Creating more than one account to take advantage of Free Credits is
            prohibited. If we&nbsp; determine that you are not using the Free
            Credits in good faith, we may terminate your&nbsp; access to the
            Service.&nbsp;&nbsp;
          </span>
        </li>
      </ul>
      <h2 className="text-2xl pt-6 pb-4">
        <span style={{ backgroundColor: "transparent" }}>
          7. Acceptable Use and Restrictions:&nbsp;&nbsp;
        </span>
      </h2>
      <ul className="list-disc list-outside ml-5 space-y-1">
        <li>
          <span style={{ backgroundColor: "transparent" }}>
            You will be granted a license to use the Selected Tracks, terms of
            which are set out in&nbsp; the Subscriber License linked with your
            Subscription Plan, to be read along with these&nbsp; Terms.&nbsp;
          </span>
        </li>
        <li>
          <span style={{ backgroundColor: "transparent" }}>
            You agree to use the Site and the Selected Tracks strictly in
            accordance with the&nbsp; provisions of the Subscriber License
            linked with your Subscription Plan and the&nbsp; Agreement as a
            whole, and for lawful purposes only. The following list
            provides&nbsp; examples of illegal or prohibited uses of the
            Service. Wubble reserves the right to&nbsp; investigate and take
            appropriate legal action against anyone violating applicable
            law&nbsp; or these rules, including removing offending content,
            suspending or terminating&nbsp; accounts, and reporting violators to
            law enforcement. You agree not to:&nbsp;&nbsp;
          </span>
        </li>
        <ul className="list-disc list-outside ml-5 space-y-1">
          <li className="ql-indent-1">
            <span style={{ backgroundColor: "transparent" }}>
              Use the Site, Services or Tracks to compete with Wubble or to
              create competing&nbsp; products or services;&nbsp;&nbsp;
            </span>
          </li>
          <li className="ql-indent-1">
            <span style={{ backgroundColor: "transparent" }}>
              Submit, upload, transmit, or make available any Submissions or
              direct the Service&nbsp; to generate any Tracks that infringes on
              any intellectual property or other proprietary rights of any&nbsp;
              party; you do not have the right to upload and use under any law
              or&nbsp; contractual/fiduciary relationship; contains viruses or
              harmful code designed to interrupt, destroy, or limit the&nbsp;
              functionality of software, hardware, or telecommunications
              equipment; &nbsp; poses a privacy or security risk to any person;
              constitutes unsolicited or unauthorized advertising, promotional
              materials,&nbsp; "junk mail," "spam," "chain letters," "pyramid
              schemes," "contests,"&nbsp; "sweepstakes," or any other form of
              solicitation; is unlawful, harmful, threatening, abusive,
              harassing, tortious, excessively&nbsp; violent, defamatory,
              vulgar, obscene, pornographic, libelous, invasive of&nbsp;
              privacy, hateful, discriminatory, or otherwise objectionable; is,
              in Wubble's sole judgment, objectionable or restricts or inhibits
              any other&nbsp; person from using or enjoying the Service or
              exposes Wubble or its users to harm or liability.&nbsp;
            </span>
          </li>
          <li className="ql-indent-1">
            <span style={{ backgroundColor: "transparent" }}>
              Interfere with or disrupt the Service, servers, or networks
              connected to the Service,&nbsp; or not adhere to any requirements,
              procedures, policies, or regulations of networks&nbsp; connected
              to the Service;&nbsp;
            </span>
          </li>
          <li className="ql-indent-1">
            <span style={{ backgroundColor: "transparent" }}>
              Violate any applicable local, state, national, or international
              law, or any regulations&nbsp; with the force of law;
            </span>
          </li>
          <li className="ql-indent-1">
            <span style={{ backgroundColor: "transparent" }}>
              Impersonate any person or entity, or falsely state, imply, or
              misrepresent your&nbsp; affiliation with any person or entity
              (including by generating, making available,&nbsp; promoting,
              labelling, or otherwise using Content, Tracks in a manner that
              suggests&nbsp; it was created by or associated with someone
              else);&nbsp;
            </span>
          </li>
          <li className="ql-indent-1">
            <span style={{ backgroundColor: "transparent" }}>
              Solicit personal information from anyone under the age of
              18;&nbsp;
            </span>
          </li>
          <li className="ql-indent-1">
            <span style={{ backgroundColor: "transparent" }}>
              Post private information, or harvest, collect, or disclose email
              addresses or other&nbsp; information about another user or person
              without their express consent, or for the&nbsp; purpose of sending
              unsolicited communications;&nbsp;
            </span>
          </li>
          <li className="ql-indent-1">
            <span style={{ backgroundColor: "transparent" }}>
              Imply that Wubble endorses any of your Submissions, your use of
              Selected Tracks,&nbsp; statements, or positions;
            </span>
          </li>
          <li className="ql-indent-1">
            <span style={{ backgroundColor: "transparent" }}>
              Further or promote any criminal activity or enterprise, or provide
              instructional&nbsp; information about illegal activities;&nbsp;
            </span>
          </li>
          <li className="ql-indent-1">
            <span style={{ backgroundColor: "transparent" }}>
              Disseminate any unlawful, harassing, libelous, abusive, or
              threatening material;&nbsp; xi. Knowingly introduce viruses,
              Trojans, worms, logic bombs, or other malicious or&nbsp;
              technologically harmful material. You must not attempt to gain
              unauthorized access&nbsp; to the Site, the server on which the
              Site is stored, or any server, computer, or&nbsp; database
              connected to the Site. You must not attack the Site via a
              denial-of-service&nbsp; attack or a distributed denial-of-service
              attack;
            </span>
          </li>
          <li className="ql-indent-1">
            <span style={{ backgroundColor: "transparent" }}>
              Use the Site, Services or any Track to train other artificial
              intelligence and machine&nbsp; learning models, tools, or
              technologies;
            </span>
          </li>
          <li className="ql-indent-1">
            <span style={{ backgroundColor: "transparent" }}>
              Circumvent, remove, alter, deactivate, degrade, or thwart any
              content protections&nbsp; or geographic restrictions on content
              available through the Service, including&nbsp; through the use of
              VPNs;
            </span>
          </li>
          <li className="ql-indent-1">
            <span style={{ backgroundColor: "transparent" }}>
              Engage in data mining, robots, scraping, or similar data gathering
              or extraction&nbsp; methods.&nbsp;&nbsp;
            </span>
          </li>
        </ul>
        <li>
          <span style={{ backgroundColor: "transparent" }}>
            If Wubble blocks your access to the Service (including by blocking
            your IP address),&nbsp; you agree not to implement any measures to
            circumvent such blocking (e.g., by&nbsp; masking your IP address or
            using a proxy IP address or VPN).&nbsp;&nbsp;
          </span>
        </li>
      </ul>
      <h2 className="text-2xl pt-6 pb-4">
        <span style={{ backgroundColor: "transparent" }}>
          8. Switching Between Subscription Plans&nbsp;&nbsp;
        </span>
      </h2>
      <p>
        <span style={{ backgroundColor: "transparent" }}>
          You can switch between Subscription Plans by accessing your account
          settings or by contacting&nbsp; our Customer Support Team at
          support@wubble.ai.&nbsp;
        </span>
      </p>
      <h2 className="text-2xl pt-6 pb-4">
        <span style={{ backgroundColor: "transparent" }}>
          9. Payments and Billing&nbsp;&nbsp;
        </span>
      </h2>
      <ul className="list-disc list-outside ml-5 space-y-1">
        <li>
          <span style={{ backgroundColor: "transparent" }}>
            Payments may be processed through third-party payment methods which
            Wubble&nbsp; makes available. You must provide accurate billing
            information and promptly update&nbsp; any changes to it (such as
            card numbers and address).&nbsp;&nbsp;
          </span>
        </li>
        <li>
          <span style={{ backgroundColor: "transparent" }}>
            You will be charged at the start of each billing cycle according to
            your Subscription&nbsp; Plan. However, the per-minute plan shall be
            billed as determined by the number of&nbsp; exhausted minutes. You
            will be automatically charged for additional periods unless&nbsp;
            you notify us of your intention not to continue at least 14 calendar
            days before the start&nbsp; of the next billing cycle.&nbsp;&nbsp;
          </span>
        </li>
        <li>
          <span style={{ backgroundColor: "transparent" }}>
            Payment for the applicable period will be automatically processed
            using the payment&nbsp; method you provided.&nbsp;&nbsp;
          </span>
        </li>
        <li>
          <span style={{ backgroundColor: "transparent" }}>
            Payments for any subscriptions to the Services are nonrefundable and
            there are no&nbsp; credits for partially used periods.&nbsp;&nbsp;
          </span>
        </li>
        <li>
          <span style={{ backgroundColor: "transparent" }}>
            Following any cancellation by you, however, you will continue to
            have access to the&nbsp; paid Services through the end of the
            Subscription Period for which payment has already&nbsp; been
            made.&nbsp;&nbsp;
          </span>
        </li>
        <li>
          <span style={{ backgroundColor: "transparent" }}>
            If the User fails to make a payment, the Company reserves the right
            to suspend or&nbsp; terminate the User's access to the Services.
          </span>
        </li>
        <li>
          <span style={{ backgroundColor: "transparent" }}>
            The Company reserves the right to change the pricing of Subscription
            Plans. Users will&nbsp; be notified of any changes at least 30 days
            in advance.&nbsp;&nbsp;
          </span>
        </li>
      </ul>
      <h2 className="text-2xl pt-6 pb-4">
        <span style={{ backgroundColor: "transparent" }}>
          10. Taxes&nbsp;&nbsp;
        </span>
      </h2>
      <p>
        <span style={{ backgroundColor: "transparent" }}>
          The tax treatment applied on each invoice will be based on the billing
          information provided by&nbsp; you, and you are solely responsible for
          the accuracy of such information. All taxes including&nbsp; and goods
          and service tax or other indirect taxes are your responsibility and
          any subscription&nbsp; price or fee shall be exclusive of such
          taxes.&nbsp;&nbsp;
        </span>
      </p>
      <h2 className="text-2xl pt-6 pb-4">
        <span style={{ backgroundColor: "transparent" }}>
          11. Links to Other Web Sites&nbsp;&nbsp;
        </span>
      </h2>
      <p>
        <span style={{ backgroundColor: "transparent" }}>
          Our Service may contain links to third-party websites or services that
          are not owned or&nbsp; controlled by us. We have no control over, and
          assume no responsibility for, the content,&nbsp; privacy policies, or
          practices of any third-party websites or services. You further
          acknowledge&nbsp;
        </span>
      </p>
      <p>
        <span style={{ backgroundColor: "transparent" }}>
          and agree that we shall not be responsible or liable, directly or
          indirectly, for any damage or&nbsp; loss caused or alleged to be
          caused by or in connection with the use of or reliance on any
          such&nbsp; content, goods, or services available on or through any
          such websites or services. We strongly&nbsp; advise you to read the
          terms and conditions and privacy policies of any third-party websites
          or&nbsp; services that you visit.&nbsp;&nbsp;
        </span>
      </p>
      <h2 className="text-2xl pt-6 pb-4">
        <span style={{ backgroundColor: "transparent" }}>
          12. Intellectual Property Rights&nbsp;&nbsp;
        </span>
      </h2>
      <ul className="list-disc list-outside ml-5 space-y-1">
        <li>
          <span style={{ backgroundColor: "transparent" }}>
            All the information, Tracks and content included in the Site and the
            Services offered&nbsp; by the Company, inclusive without limitation
            any text, illustrations, graphics, sound,&nbsp; sound recording,
            musical works, clips, graphic segments, software applications,&nbsp;
            graphs, photos, video templates, plugins, Company’s name,
            trademarks, and logos,&nbsp; including without limitation those used
            in the Site, are protected by copyright and&nbsp; intellectual
            property rights of the Company or its licensors ("Proprietary
            Content").&nbsp;&nbsp;
          </span>
        </li>
        <li>
          <span style={{ backgroundColor: "transparent" }}>
            You agree not to use the Company’s name, trademarks, and logos,
            including without&nbsp; limitation those used in the
            Site.&nbsp;&nbsp;
          </span>
        </li>
        <li>
          <span style={{ backgroundColor: "transparent" }}>
            You hereby acknowledge and confirm that you do not and will not have
            any ownership&nbsp; and/or intellectual property rights in the
            Proprietary Content and/or Tracks, and that&nbsp; your right is
            restricted to the limited rights granted to you in the Subscriber
            License&nbsp; and the Terms.&nbsp;&nbsp;
          </span>
        </li>
        <li>
          <span style={{ backgroundColor: "transparent" }}>
            The Agreement grants you authorization to use the Site and Tracks
            strictly in&nbsp; accordance with the provisions of the Agreement
            read as a whole and the Subscriber&nbsp; License and is conditioned
            by your undertaking not to violate or be involved in any&nbsp;
            activities that violate any terms of the Agreement and Subscriber
            License, and&nbsp; specifically in activities that cause the
            violation of intellectual property rights and/or&nbsp; deviation
            from the provisions of the Agreement.&nbsp;&nbsp;
          </span>
        </li>
        <li>
          <span style={{ backgroundColor: "transparent" }}>
            You may not reverse engineer, decompile, or disassemble any part of
            any source code&nbsp; contained within the Site, or any of its
            contents.&nbsp;&nbsp;
          </span>
        </li>
      </ul>
      <h2 className="text-2xl pt-6 pb-4">
        <span style={{ backgroundColor: "transparent" }}>
          13. Copyright Infringement Notification Policy&nbsp;&nbsp;
        </span>
      </h2>
      <ul className="list-disc list-outside ml-5 space-y-1">
        <li>
          <span style={{ backgroundColor: "transparent" }}>
            We respect the intellectual property rights of others. If Company is
            notified that any&nbsp; Track(s) infringe third party copyright or
            other rights, Company may in its sole&nbsp; discretion remove such
            Track(s) from the Site or take other steps that it deems&nbsp;
            necessary, without any prior notification.&nbsp;
          </span>
        </li>
        <li>
          <span style={{ backgroundColor: "transparent" }}>
            If you believe that any Track(s) infringe your intellectual property
            or other rights,&nbsp; please notify Company of your claim in
            accordance with the Notices provision below.&nbsp;
          </span>
        </li>
        <li>
          <span style={{ backgroundColor: "transparent" }}>
            Company will process and investigate notices of alleged infringement
            and will take&nbsp; appropriate actions under applicable law.&nbsp;
          </span>
        </li>
        <li>
          <span style={{ backgroundColor: "transparent" }}>
            To be effective, the claim must be in writing and contain the
            following information:
          </span>
        </li>
        <ul className="list-disc list-outside ml-5 space-y-1">
          <li className="ql-indent-1">
            <span style={{ backgroundColor: "transparent" }}>
              a physical or electronic signature of a person authorized to act
              on behalf of the&nbsp; owner of the copyright or other
              intellectual property interest that is allegedly infringed;
            </span>
          </li>
          <li className="ql-indent-1">
            <span style={{ backgroundColor: "transparent" }}>
              identification of the copyrighted work or other intellectual
              property claimed to&nbsp; have been infringed;
            </span>
          </li>
          <li className="ql-indent-1">
            <span style={{ backgroundColor: "transparent" }}>
              identification of the content that is the subject of infringing
              activity;&nbsp;
            </span>
          </li>
          <li className="ql-indent-1">
            <span style={{ backgroundColor: "transparent" }}>
              claimant’s address, telephone number, and email address.
            </span>
          </li>
        </ul>
        <li>
          <span style={{ backgroundColor: "transparent" }}>
            You may be held accountable for any damages as well as costs and
            expenses (including&nbsp; attorneys’ fees as well as time costs
            expended to investigate your allegation) if there is&nbsp; no
            reasonable basis to your allegation.&nbsp;&nbsp;
          </span>
        </li>
      </ul>
      <h2 className="text-2xl pt-6 pb-4">
        <span style={{ backgroundColor: "transparent" }}>
          14. Open-Source Software&nbsp;&nbsp;
        </span>
      </h2>
      <p>
        <span style={{ backgroundColor: "transparent" }}>
          Some of the software required by or included in our Services may be
          offered under an open source license. In the event that the
          open-source software license terms expressly supersede or&nbsp; alter
          the Terms, the open-source license terms shall prevail for that
          limited purpose only.&nbsp;&nbsp;
        </span>
      </p>
      <h2 className="text-2xl pt-6 pb-4">
        <span style={{ backgroundColor: "transparent" }}>
          15. Compliance with Law&nbsp;&nbsp;
        </span>
      </h2>
      <p>
        <span style={{ backgroundColor: "transparent" }}>
          You will comply with all applicable laws, regulations, and third-party
          rights (including without&nbsp; limitation laws regarding the import
          or export of data or software, privacy, and local laws).&nbsp; You
          hereby irrevocably and unconditionally agree that we may monitor your
          use of the&nbsp; Services to ensure the quality of the Service,
          Service improvement (where required), and&nbsp; compliance with the
          Terms.&nbsp;&nbsp;
        </span>
      </p>
      <h2 className="text-2xl pt-6 pb-4">
        <span style={{ backgroundColor: "transparent" }}>
          16. Sanctioned Countries&nbsp;
        </span>
      </h2>
      <p>
        <span style={{ backgroundColor: "transparent" }}>
          The Company is committed to complying with the sanctions laws and
          regulations of Singapore.&nbsp; Accordingly, we will not trade with or
          provide any services to sanctioned countries or&nbsp;
          individuals/entities named on a sanctions list.&nbsp;&nbsp;
        </span>
      </p>
      <h2 className="text-2xl pt-6 pb-4">
        <span style={{ backgroundColor: "transparent" }}>
          17. Privacy Policy&nbsp;&nbsp;
        </span>
      </h2>
      <p>
        <span style={{ backgroundColor: "transparent" }}>
          We really care about your privacy and we make our best efforts to be
          fully transparent about&nbsp; our use of your information. Please
          review our Privacy Policy and Cookies Policy to learn how&nbsp; we
          collect and use your personal information and cookies.&nbsp;&nbsp;
        </span>
      </p>
      <h2 className="text-2xl pt-6 pb-4">
        <span style={{ backgroundColor: "transparent" }}>
          18. Termination&nbsp;&nbsp;
        </span>
      </h2>
      <ul className="list-disc list-outside ml-5 space-y-1">
        <li>
          <span style={{ backgroundColor: "transparent" }}>
            Termination by User: You can terminate your subscription at any time
            by contacting&nbsp; Customer Support at support@wubble.ai or using
            the cancellation feature in your&nbsp; account. Your subscription
            will terminate at the end of the current billing period, and&nbsp;
            no further charges will be incurred. You will continue to have
            access to Services under&nbsp; the Agreement until the end of the
            current billing period. Please see Payment Terms for&nbsp; more
            information.&nbsp;
          </span>
        </li>
        <li>
          <span style={{ backgroundColor: "transparent" }}>
            Termination by Wubble: Wubble reserves the right to suspend or
            terminate the&nbsp; Agreement and your access to the Services,
            without prior notice, in case of any breach&nbsp; of the Agreement
            or unauthorized use of the Services or Platform. Upon
            termination,&nbsp; your right to use the Service will immediately
            cease. Any fees paid for use of the&nbsp; Services are
            non-refundable. For clarity, upon termination of your account,
            any&nbsp; outstanding obligations and provisions that should survive
            termination, such as&nbsp; ownership, warranty disclaimers,
            indemnity, and limitations of liability, will remain in&nbsp;
            effect.&nbsp;&nbsp;
          </span>
        </li>
      </ul>
      <h2 className="text-2xl pt-6 pb-4">
        <span style={{ backgroundColor: "transparent" }}>
          19. Disclaimer of Warranties&nbsp;&nbsp;
        </span>
      </h2>
      <ul className="list-disc list-outside ml-5 space-y-1">
        <li>
          <span style={{ backgroundColor: "transparent" }}>
            Your use of the Service is at your sole risk. To the maximum extent
            permitted by&nbsp; applicable law, you understand and agree that the
            Site, Services and Tracks made&nbsp; accessible to you by the
            Company are provided on an “As-Is or As Available” basis&nbsp;
            without warranty of any kind, including warranties of
            merchantability, fitness for a&nbsp; particular purpose,
            non-infringement, or any other warranty that may arise under
            any&nbsp; applicable law. We, our subsidiaries, affiliates, and
            licensors do not warrant or&nbsp; undertake that (a) the Services
            will be uninterrupted, secure, or available at any&nbsp; particular
            time or location; (b) errors or defects will be corrected; (c) the
            Services are free of viruses or other harmful components; or (d) the
            Services will meet your&nbsp; requirements or (e) will be
            updated.&nbsp;&nbsp;
          </span>
        </li>
        <li>
          <span style={{ backgroundColor: "transparent" }}>
            Neither do we warrant that this website is free from computer
            viruses, bugs, or other&nbsp; harmful components and you should have
            the necessary security software and firewall&nbsp; in place before
            accessing this website.&nbsp;&nbsp;
          </span>
        </li>
        <li>
          <span style={{ backgroundColor: "transparent" }}>
            The Service and your data maintained on the Service is subject to
            malfunctions and&nbsp; losses and Company is not responsible for,
            and does not warrant any such malfunction&nbsp; or loss. You are
            urged to backup any important data of yours that you maintain on
            the&nbsp; Service to avoid any inconvenience in case of such
            loss.&nbsp;&nbsp;
          </span>
        </li>
      </ul>
      <h2 className="text-2xl pt-6 pb-4">
        <span style={{ backgroundColor: "transparent" }}>
          20. Limitation of Liability&nbsp;&nbsp;
        </span>
      </h2>
      <ul className="list-disc list-outside ml-5 space-y-1">
        <li>
          <span style={{ backgroundColor: "transparent" }}>
            The presence of user-posted content or Submissions on the Service
            does not imply&nbsp; Wubble's approval or endorsement, and Wubble
            disclaims all liability in connection&nbsp; with the use of the
            Submissions or user-posted content and any activities related
            to&nbsp; accessing, browsing, contributing to, or using the Service.
          </span>
        </li>
        <li>
          <span style={{ backgroundColor: "transparent" }}>
            In no event shall we, nor our directors, employees, partners,
            agents, contractors,&nbsp; suppliers, or affiliates, be liable for
            any indirect, incidental, special, consequential, or&nbsp; punitive
            damages, including without limitation, loss of profits, data, use,
            goodwill, or&nbsp; other intangible losses, resulting from (i) your
            access to or use of or inability to access&nbsp; or use the
            Services; (ii) any conduct or content of any third party on the
            Services; (iii)&nbsp; any Content obtained from the Services; and
            (iv) unauthorized access, use, or alteration&nbsp; of your
            transmissions or content.&nbsp;&nbsp;
          </span>
        </li>
        <li>
          <span style={{ backgroundColor: "transparent" }}>
            The Company’s total liability for any claims arising out of or
            related to this Agreement&nbsp; or the Services will not exceed the
            amount paid by the User to the Company in the 3&nbsp; months
            preceding the event that gave rise to the claim.&nbsp;&nbsp;
          </span>
        </li>
      </ul>
      <h2 className="text-2xl pt-6 pb-4">
        <span style={{ backgroundColor: "transparent" }}>
          21. Indemnification&nbsp;&nbsp;
        </span>
      </h2>
      <p>
        <span style={{ backgroundColor: "transparent" }}>
          You agree to indemnify and hold us, our affiliates, contractors, and
          licensors and their&nbsp; respective directors, officers, employees,
          and agents harmless from and against any, and all&nbsp;
        </span>
      </p>
      <p>
        <span style={{ backgroundColor: "transparent" }}>
          damages, claims, and expenses, including attorney’s fees, arising out
          of your access and use of&nbsp; the Site and Services.&nbsp;&nbsp;
        </span>
      </p>
      <h2 className="text-2xl pt-6 pb-4">
        <span style={{ backgroundColor: "transparent" }}>
          22. License to Feedback&nbsp;&nbsp;
        </span>
      </h2>
      <p>
        <span style={{ backgroundColor: "transparent" }}>
          You acknowledge that Wubble may use any suggestions, ideas, proposals,
          or other materials&nbsp; you provide ("Feedback"), whether related to
          the Site, Service, or otherwise, and agree that&nbsp; such Feedback is
          not confidential. You grant Wubble and its collaborators a
          worldwide,&nbsp; royalty-free, fully paid-up, non-exclusive,
          perpetual, irrevocable, transferable, and fully&nbsp; sublicensable
          (through multiple tiers) license to use and exploit the Feedback
          without any&nbsp; additional consideration to you or any third
          party.&nbsp;&nbsp;
        </span>
      </p>
      <h2 className="text-2xl pt-6 pb-4">
        <span style={{ backgroundColor: "transparent" }}>
          23. Monitoring&nbsp;&nbsp;
        </span>
      </h2>
      <p>
        <span style={{ backgroundColor: "transparent" }}>
          Wubble reserves the right to monitor, evaluate, alter, or remove
          Tracks before or after they&nbsp; appear on the Site. We may also
          analyse your access to or use of the Site and subject to the&nbsp;
          terms of the Privacy Policy, disclose information regarding your
          access and use, and the&nbsp; circumstances surrounding it, to anyone
          for any reason or purpose.&nbsp;&nbsp;
        </span>
      </p>
      <h2 className="text-2xl pt-6 pb-4">
        <span style={{ backgroundColor: "transparent" }}>
          24. Governing Law&nbsp;
        </span>
      </h2>
      <p>
        <span style={{ backgroundColor: "transparent" }}>
          These Terms shall be governed and construed in accordance with the
          laws of Singapore,&nbsp; without regard to its conflict of law
          provisions.&nbsp;&nbsp;
        </span>
      </p>
      <h2 className="text-2xl pt-6 pb-4">
        <span style={{ backgroundColor: "transparent" }}>
          25. Miscellaneous&nbsp;&nbsp;
        </span>
      </h2>
      <ul className="list-disc list-outside ml-5 space-y-1">
        <li>
          <span style={{ backgroundColor: "transparent" }}>
            Our failure to enforce any right or provision of these Terms will
            not be considered a&nbsp; waiver of those rights.
          </span>
        </li>
        <li>
          <span style={{ backgroundColor: "transparent" }}>
            If any provision of these Terms is held to be invalid or
            unenforceable by a court, the&nbsp; remaining provisions of these
            Terms will remain in effect.&nbsp;&nbsp;
          </span>
        </li>
        <li>
          <span style={{ backgroundColor: "transparent" }}>
            The Agreement constitute the entire agreement between us regarding
            our Service and&nbsp; supersede and replace any prior agreements we
            might have between us regarding the&nbsp; Service.&nbsp;
          </span>
        </li>
        <li>
          <span style={{ backgroundColor: "transparent" }}>
            Notices to Company must be sent by email to support@wubble.ai and
            must provide&nbsp; the Subscriber’s name, the email address
            associated with the Subscription Account and&nbsp; the User’s
            username. Notices to you may be sent to the email address associated
            with&nbsp; your subscription.&nbsp;&nbsp;
          </span>
        </li>
      </ul>
      <h2 className="text-2xl pt-6 pb-4">
        <span style={{ backgroundColor: "transparent" }}>
          26. Contact Information&nbsp;
        </span>
      </h2>
      <p>
        <span style={{ backgroundColor: "transparent" }}>
          For any inquiries or support, please contact Wubble at
          support@wubble.ai.&nbsp;&nbsp;
        </span>
      </p>
      <h2 className="text-2xl pt-6 pb-4">
        <span style={{ backgroundColor: "transparent" }}>
          27. Definitions&nbsp;&nbsp;
        </span>
      </h2>
      <ul className="list-disc list-outside ml-5 space-y-1">
        <li>
          <span style={{ backgroundColor: "transparent" }}>
            " Service or Services" means the Platform provided by the Company on
            which Tracks&nbsp; are created, produced and accessed, whether
            non-customised or by receiving&nbsp; Submissions; and may include a
            public forum where Tracks can be shared with other&nbsp;
            Subscribers.&nbsp;&nbsp;
          </span>
        </li>
        <li>
          <span style={{ backgroundColor: "transparent" }}>
            "Content" means any Submissions, Tracks, media, or materials
            generated, uploaded,&nbsp; or otherwise made available through the
            Services.&nbsp;&nbsp;
          </span>
        </li>
        <li>
          <span style={{ backgroundColor: "transparent" }}>
            “Platform” or “Site” means the website, mobile application, and/or
            other future&nbsp; platforms offered, provided and made available to
            you by Company or its affiliates&nbsp; which is supported by
            artificial intelligence for creating and producing
            customisable&nbsp; and non-customisable Tracks.&nbsp;&nbsp;
          </span>
        </li>
        <li>
          <span style={{ backgroundColor: "transparent" }}>
            “Selected Tracks” means the Tracks that are selected by User for
            download during&nbsp; the Subscription Period.&nbsp;&nbsp;
          </span>
        </li>
        <li>
          <span style={{ backgroundColor: "transparent" }}>
            “Submissions” means input data and content that Subscriber provides
            on the Service&nbsp; which may include videos, audio, images,
            information, text, and other materials and&nbsp; media.&nbsp;
          </span>
        </li>
        <li>
          <span style={{ backgroundColor: "transparent" }}>
            “Subscriber” or “User” means you, any person or entity that accesses
            the Platform/Site&nbsp; and/or subscribes for the Services.&nbsp;
          </span>
        </li>
        <li>
          <span style={{ backgroundColor: "transparent" }}>
            “Subscription Period” means the term or number of minutes specified
            in the&nbsp; Subscription Plan subscribed / purchased by the
            Subscriber.&nbsp;&nbsp;
          </span>
        </li>
        <li>
          <span style={{ backgroundColor: "transparent" }}>
            "Subscription Plan" means the different pricing models and/or plans
            offered by the&nbsp; Company for use of the Services.&nbsp;&nbsp;
          </span>
        </li>
        <li>
          <span style={{ backgroundColor: "transparent" }}>
            “Tracks” means music compositions/ songs.&nbsp;&nbsp;
          </span>
        </li>
      </ul>
      <p>
        <br />
      </p>
      <p>
        <span style={{ backgroundColor: "transparent" }}>
          By accessing or using the Services, the User acknowledges that they
          have read,&nbsp; understood, and agreed to be bound by the terms of
          this Agreement.&nbsp;
        </span>
      </p>
      <p>
        <br />
      </p>
    </>
  );

  return <div>{rawHtmlContent}</div>;
};

export default TermsOfUse;
