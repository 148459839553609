import React, { useState, useEffect } from "react";
import { PencilToSquare } from "@gravity-ui/icons";
import apiService from "../../api/apiService";
import Toast from "../generic_components/Toast";
import { getCookie } from "../../utils/cookieUtils";
import { useLocalState } from "../../contexts/LocalStateProvider";

const TeamDetails = ({ teamData, avatarSrc }) => {
  const [isEditingName, setIsEditingName] = useState(false);
  const [isEditingDescription, setIsEditingDescription] = useState(false);
  const [name, setName] = useState(teamData?.name);
  const [description, setDescription] = useState(teamData?.description);
  const [editedName, setEditedName] = useState(teamData?.name);
  const [editedDescription, setEditedDescription] = useState(
    teamData?.description
  );
  const [userRole, setUserRole] = useState(null);
  const { updateLocalState } = useLocalState();

  const [logo, setLogo] = useState(avatarSrc);
  const [toastMessage, setToastMessage] = useState("");
  const [toastType, setToastType] = useState("info");

  useEffect(() => {
    const fetchUserRole = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_SERVER_ENDPOINT}/api/checkUserRole?team_id=${teamData.team_id}`,
          {
            headers: {
              Authorization: `Bearer ${getCookie("token")}`,
            },
          }
        );
        const data = await response.json();
        setUserRole(data.role);
      } catch (error) {
        console.error("Error fetching user role:", error);
      }
    };

    fetchUserRole();
  }, [teamData]);

  const handleNameChange = (e) => setEditedName(e.target.value);
  const handleDescriptionChange = (e) => setEditedDescription(e.target.value);

  const handleLogoUpload = async (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("logo", file);
    formData.append("team_id", teamData.team_id);

    try {
      const result = await apiService.updateTeamLogo(formData);
      if (result.success) {
        setLogo(result.team.logo);
        setToastMessage("Team logo updated successfully");
        setToastType("success");
        updateLocalState("teamNameAndAvatar", null);
        updateLocalState("teamSettingsData", null);
      } else {
        setToastMessage(result.error || "An error occurred");
        setToastType("error");
      }
    } catch (error) {
      setToastMessage("An error occurred while updating the logo");
      setToastType("error");
    }
  };

  const handleSave = async (field) => {
    let taskName, payload;

    switch (field) {
      case "name":
        taskName = "updateTeamName";
        payload = { teamName: editedName, team_id: teamData.team_id };
        break;
      case "description":
        taskName = "updateTeamDescription";
        payload = { description: editedDescription, team_id: teamData.team_id };
        break;
      default:
        return;
    }

    try {
      const result = await apiService.sendRequest(taskName, payload);
      if (result.success) {
        setToastMessage(result.success);
        setToastType("success");
        if (field === "name") {
          setName(editedName);
        } else if (field === "description") {
          setDescription(editedDescription);
        }
        updateLocalState("teamNameAndAvatar", null);
        updateLocalState("teamSettingsData", null);
      } else {
        setToastMessage(result.error || "An error occurred");
        setToastType("error");
      }
    } catch (error) {
      setToastMessage("An error occurred while saving");
      setToastType("error");
    }

    if (field === "name") setIsEditingName(false);
    if (field === "description") setIsEditingDescription(false);
  };

  const imageSource =
    typeof logo === "string"
      ? logo
      : (() => {
          if (logo instanceof File) {
            return URL.createObjectURL(logo);
          } else if (logo && logo.type === "Buffer") {
            const binaryData = new Uint8Array(logo.data);
            const blob = new Blob([binaryData], { type: "image/jpeg" }); // Change the type accordingly
            return URL.createObjectURL(blob);
          }
          return null;
        })();

  const handleCancel = (field) => {
    if (field === "name") {
      setEditedName(name);
      setIsEditingName(false);
    } else if (field === "description") {
      setEditedDescription(description);
      setIsEditingDescription(false);
    }
  };

  return (
    <div className="space-y-2 mb-8">
      <h2 className="font-normal text-base text-white ml-2 mb-2">General</h2>
      <div className="flex flex-col bg-[#1D1B1D] w-full rounded-lg py-1 px-3">
        <div
          className={`flex md:flex-row ${
            isEditingName && `flex-col`
          } justify-between border-b border-b-[#2C2A2C] py-5 rounded-t-lg`}
        >
          <div className="flex flex-col sm:mb-0 w-full">
            <label className="block text-base font-light">Name</label>
            <p className="text-sm font-extralight text-[#878188]">
              The name of your team
            </p>
          </div>

          {isEditingName ? (
            <div className="flex flex-col mt-4 md:mt-0 md:flex-row gap-2.5 md:items-center w-full">
              <input
                type="text"
                value={editedName}
                onChange={handleNameChange}
                className="flex w-full px-3 placeholder-[#878188] h-8 font-extralight text-sm rounded-full border border-solid border-[#878188] bg-transparent text-white"
              />
              <div className="flex flex-row gap-2 justify-end">
                <button
                  className="text-sm px-2 h-8 [background:var(--Color-Opacity-White-5,rgba(255,255,255,0.05))] text-[#878188] rounded-md"
                  onClick={() => handleCancel("name")}
                >
                  Cancel
                </button>
                <button
                  className="h-8 text-sm bg-white hover:bg-[#E0DFE1] px-2 text-black rounded-md"
                  onClick={() => handleSave("name")}
                >
                  Save
                </button>
              </div>
            </div>
          ) : (
            <div className="flex items-center gap-3 sm:mt-0 w-full justify-end">
              <span className="font-extralight text-sm">{name}</span>
              {userRole === "Admin" && (
                <button onClick={() => setIsEditingName(true)}>
                  <PencilToSquare className="hover:text-white text-[#878188] transition duration-200" />
                </button>
              )}
            </div>
          )}
        </div>

        <div className="border-b border-b-[#2C2A2C] bg-[#1D1B1D] py-5">
          <div className="flex justify-between items-center">
            <div className="flex flex-row items-center gap-1">
              <label className="text-base font-light">Description</label>
              <p className="text-sm font-extralight text-[#878188]">
                (optional)
              </p>
            </div>
            {!isEditingDescription && userRole === "Admin" && (
              <button onClick={() => setIsEditingDescription(true)}>
                <PencilToSquare className="hover:text-white text-[#878188] transition duration-200" />
              </button>
            )}
          </div>
          <p className="text-sm font-extralight text-[#878188] mb-3">
            Brief overview to improve generation relevancy.
          </p>
          {isEditingDescription ? (
            <div>
              <textarea
                value={editedDescription}
                onChange={handleDescriptionChange}
                className="w-full p-2 font-extralight text-sm rounded-xl placeholder-[#4E4A4F] bg-transparent text-white border border-[#4E4A4F] h-20 focus:outline-none"
              />
              <div className="flex justify-end gap-2 mt-2">
                <button
                  className="text-sm px-2 h-8 [background:var(--Color-Opacity-White-5,rgba(255,255,255,0.05))] text-[#878188] rounded-md"
                  onClick={() => handleCancel("description")}
                >
                  Cancel
                </button>
                <button
                  className="h-8 text-sm bg-white hover:bg-[#E0DFE1] px-2 text-black rounded-md"
                  onClick={() => handleSave("description")}
                >
                  Save
                </button>
              </div>
            </div>
          ) : (
            <p className="text-sm text-white mt-2 font-extralight break-words overflow-hidden">
              {description}
            </p>
          )}
        </div>

        <div className="flex flex-row justify-between items-center bg-[#1D1B1D] py-5 rounded-b-lg">
          <div className="flex items-center gap-1">
            <label className="text-base font-light">Logo</label>
            <p className="text-sm font-extralight text-[#878188]">(optional)</p>
          </div>
          <div className="flex items-center relative">
            {logo ? (
              <img
                src={imageSource}
                alt="Team Logo"
                className="w-14 h-14 object-cover rounded-full"
              />
            ) : (
              <div className="flex w-14 h-14 bg-[#3C393C] items-center justify-center rounded-full">
                <img src="Organization.svg" alt="Default Logo" />
              </div>
            )}
            <input
              type="file"
              className="hidden"
              id="file-input-logo"
              onChange={handleLogoUpload}
              accept="image/png, image/jpeg, image/webp"
            />
            {userRole === "Admin" && (
              <label
                htmlFor="file-input-logo"
                className="absolute left-7 bottom-0 p-2 bg-[#2C2A2C] rounded-full cursor-pointer"
              >
                <PencilToSquare className="text-white hover:text-[#878188] transition duration-200" />
              </label>
            )}
          </div>
        </div>
        {toastMessage && (
          <Toast
            type={toastType}
            message={toastMessage}
            onClose={() => setToastMessage("")}
          />
        )}
      </div>
    </div>
  );
};

export default TeamDetails;
