import React, { useState, useEffect, useRef } from "react";
import Dropdown from "../generic_components/Dropdown";
import Modal from "../modal/Modal";
import ModalContent from "../modal/ModalContent";
import { useLocalState } from "../../contexts/LocalStateProvider";
import { useNavigate } from "react-router-dom";
import { File, Xmark } from "@gravity-ui/icons";
import apiService from "../../api/apiService";
import { getCookie } from "../../utils/cookieUtils";
import Toast from "../generic_components/Toast";
import { useLocation } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";

const truncateFileName = (fileName, maxLength) => {
  const extension = fileName.split(".").pop();
  const baseName = fileName.substring(
    0,
    fileName.length - extension.length - 1
  );

  if (baseName.length + extension.length + 1 <= maxLength) {
    return fileName;
  }

  const truncatedBaseName = baseName.substring(
    0,
    maxLength - extension.length - 1
  );
  return `${truncatedBaseName}...${extension}`;
};

const CustomInput = ({
  className,
  dropdownStyle,
  closeModal,
  audioFile,
  requestData,
  project_id,
  chatPage,
  imageSrc,
  mood,
  fromDiscoverPage,
  isLoadingAudio,
  id,
}) => {
  const { localState, updateLocalState } = useLocalState();
  const [modalContent, setModalContent] = useState(null);
  const [file, setFile] = useState(null);
  const [textPrompt, setTextPrompt] = useState(localState.textPrompt || "");
  const [filePreview, setFilePreview] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastType, setToastType] = useState("info");
  const navigate = useNavigate();
  const textareaRef = useRef(null);
  const maxHeight = 90;
  const [inputDisplay, setInputDisplay] = useState(chatPage);
  const [isMultiLine, setIsMultiLine] = useState(false);
  const [inChatPage, setInChatPage] = useState(chatPage);
  const { isLoggedIn } = useAuth();
  const [diceIsClicked, setDiceIsClicked] = useState(false);
  const [lastPromptIndex, setLastPromptIndex] = useState(null);
  const [isHovered, setIsHovered] = useState(false);
  const [shouldGenerate, setShouldGenerate] = useState(false);
  const [disableGenerate, setDisableGenerate] = useState(false);

  const randomPrompts = [
    "Create a pop hit!",
    "Compose a classical masterpiece.",
    "Write a relaxing jazz tune.",
    "Generate an epic film score.",
    "Design a funky dance track.",
  ];

  const showToast =
    toastMessage === "File size exceeds the 4 MB limit." ||
    toastMessage === "Please enter some text." ||
    toastMessage === "Please wait for the current response to finish." ||
    toastMessage === "Maximum character limit exceeded.";

  const handleDiceClick = () => {
    setDiceIsClicked(true);

    // Randomly select an index different from the last one
    let randomIndex;
    do {
      randomIndex = Math.floor(Math.random() * randomPrompts.length);
    } while (randomIndex === lastPromptIndex); // Ensure new index is different from the last

    // Update state with the random prompt
    const randomPrompt = randomPrompts[randomIndex];
    setTextPrompt(randomPrompt);
    setLastPromptIndex(randomIndex); // Update last prompt index state

    // Reset the clicked state after animation (0.3s for example)
    setTimeout(() => {
      setDiceIsClicked(false);
    }, 300); // Adjust duration as needed
  };

  const location = useLocation();

  useEffect(() => {
    updateLocalState("textPrompt", "");
    updateLocalState("file", null);

    // Reset textPrompt when location changes
    setTextPrompt("");
    setFile(null);
  }, [location.pathname]);

  useEffect(() => {
    setInputDisplay(false);
  }, [file]);

  // useEffect(() => {
  //   if (textareaRef.current) {
  //     textareaRef.current.style.height = "auto";
  //     textareaRef.current.style.height = `${Math.min(
  //       textareaRef.current.scrollHeight,
  //       maxHeight
  //     )}px`;
  //     textareaRef.current.style.overflow =
  //       textareaRef.current.scrollHeight > maxHeight ? "auto" : "hidden";

  //     // Check if the text area is empty or contains only one non-blank line
  //     const lines = textPrompt
  //       ?.split("\n")
  //       .filter((line) => line.trim() !== "");
  //     const isOneLine =
  //       lines?.length <= 1 && textareaRef.current.scrollHeight <= 24;

  //     if (isOneLine && chatPage && !file) {
  //       setInputDisplay(true);
  //     } else if (!chatPage) {
  //       setInputDisplay(false);
  //     }
  //   }
  // }, [textPrompt, chatPage, file]);

  useEffect(() => {
    setIsUploading(isLoadingAudio);
  }, [isLoadingAudio]);

  useEffect(() => {}, [textPrompt, chatPage, file]);

  const handleChange = (e) => {
    let inputValue = e.target.value; // Create a copy of the input value
    const wordCount = inputValue.trim().split(/\s+/).length;

    // Character limit
    const charLimit = 2000;

    // Enforce character limit and truncate pasted input
    if (inputValue.length > charLimit) {
      setDisableGenerate(true); // Disable the generate button
      setToastMessage("Maximum character limit exceeded."); // Display toast message
      setToastType("error"); // Set toast type to error
    } else {
      setDisableGenerate(false); // Enable the generate button
    }

    setTextPrompt(inputValue);

    // Calculate the number of lines
    const lines = inputValue.split("\n").filter((line) => line.trim() !== "");

    // Adjust the textarea height dynamically
    if (textareaRef.current) {
      textareaRef.current.style.height = "auto"; // Reset height
      textareaRef.current.style.height = `${Math.min(
        textareaRef.current.scrollHeight,
        100
      )}px`;
      textareaRef.current.style.overflow =
        textareaRef.current.scrollHeight > 100 ? "auto" : "hidden";
    }

    // Check for input display logic based on conditions
    if (
      lines.length <= 1 &&
      textareaRef.current.scrollHeight <= 24 &&
      inputValue.length <= 90 && // Character limit applied here
      chatPage &&
      !file
    ) {
      setInputDisplay(true);
    } else if (lines.length > 1 || inputValue.length > 71) {
      setInputDisplay(false);
    }
  };

  useEffect(() => {
    // Calculate the number of lines
    const lines = textPrompt?.split("\n").filter((line) => line.trim() !== "");

    // Adjust the textarea height dynamically
    if (textareaRef.current) {
      textareaRef.current.style.height = "auto"; // Reset height
      textareaRef.current.style.height = `${Math.min(
        textareaRef.current.scrollHeight,
        100
      )}px`;
      textareaRef.current.style.overflow =
        textareaRef.current.scrollHeight > 80 ? "auto" : "hidden";
    }

    chatPage &&
      textareaRef.current.scrollHeight <= 98 &&
      updateLocalState("textInputHeight", textareaRef.current.scrollHeight);

    if (
      lines?.length <= 1 &&
      textareaRef.current.scrollHeight <= 24 &&
      textPrompt.length <= 90 &&
      chatPage &&
      !file
    ) {
      // chatPage && updateLocalState("textInputHeight", null);
      setInputDisplay(true);
    } else if (
      lines?.length > 1 ||
      textPrompt.length > 71 ||
      textareaRef.current.scrollHeight > 24
    ) {
      setInputDisplay(false);
    }
  }, [textPrompt, chatPage, file]);

  useEffect(() => {
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setFilePreview(reader.result);
        setIsUploading(false);
      };
      setIsUploading(true);
      reader.readAsDataURL(file.file);
    } else {
      setFilePreview(null);
    }
  }, [file]);

  useEffect(() => {
    if (audioFile && typeof audioFile === "string") {
      const fileName = audioFile.split("/").pop();
      setFile({ name: fileName });
    } else if (audioFile) {
      setFile({ name: audioFile.name, file: audioFile, type: audioFile.type });
    }
  }, [audioFile]);

  const handleOptionSelect = (option) => {
    setModalContent(option);
  };

  const MAX_FILE_SIZE_MB = 4; // Maximum file size in MB
  const MAX_FILE_SIZE_BYTES = MAX_FILE_SIZE_MB * 1024 * 1024; // Convert to bytes

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      if (selectedFile.size > MAX_FILE_SIZE_BYTES) {
        // File size exceeds the limit
        setToastMessage("File size exceeds the 4 MB limit.");
        setToastType("error");
        setIsUploading(false);
        return;
      }

      setIsUploading(true);

      fromDiscoverPage
        ? setFile(selectedFile)
        : setTimeout(() => {
            setFile({
              name: selectedFile.name,
              type: selectedFile.type,
              file: selectedFile,
            });
            setIsUploading(false);
          }, 2000);
    }
    setModalContent(null);
  };

  const handleFileRemove = () => {
    setFile(null);
  };

  // Update textPrompt when localState.textPrompt changes
  useEffect(() => {
    if (localState.textPrompt !== undefined) {
      setTextPrompt(localState.textPrompt);
    }
  }, [localState.textPrompt]);

  // Update file when localState.file changes
  useEffect(() => {
    if (!localState.file) {
      setFile(localState.file);
    }
  }, [localState.file]);

  useEffect(() => {
    if (localState.suggestionClicked) {
      const { text, file } = localState.suggestionClicked;
      setTextPrompt(text);

      const processFile = async () => {
        let newFile = null;
        setIsUploading(true);
        setTimeout(async () => {
          if (file) {
            newFile = {
              name: file.name,
              type: file.type,
              file: file.file,
            };
            setFile(newFile);
            await handleGenerate(newFile);
          }
          setFile(newFile);
          setIsUploading(false);
        }, 2000);
      };

      file ? processFile() : text && handleGenerate();
      updateLocalState("suggestionClicked", null);
    }
  }, [localState.suggestionClicked]);

  const handleGenerate = async (currentFile) => {
    currentFile = currentFile && currentFile.file ? currentFile : file;

    gtag("event", "generate_music", {
      event_category: "Music Actions",
      event_label: "Generate Button",
      value: 1,
    });

    const prompt = localState.suggestionClicked
      ? localState.suggestionClicked?.text?.trim()
      : textPrompt?.trim();

    if (localState.responseInProgress) {
      setToastMessage("Please wait for the current response to finish.");
      setToastType("info");
      return;
    }

    if (!isLoggedIn) {
      updateLocalState("textPrompt", prompt);
      updateLocalState("file", currentFile);
      updateLocalState("showLoginModal", (prev) => !prev);
      return;
    }

    if (!prompt?.trim() && currentFile) {
      setToastMessage("Please enter some text.");
      setToastType("error");
      return; // Exit the function
    }

    if (prompt?.trim() || currentFile) {
      const userId = getCookie("user_id");

      try {
        let createProject = requestData?.createProject;
        let projectId;

        if (createProject) {
          // Case where a new project needs to be created
          const projectPayload = {
            user_id: userId,
            project_name: "New Project",
            project_description: prompt || "Project Description",
          };

          const createProjectResponse = await apiService.sendRequest(
            "createProject",
            projectPayload
          );
          if (createProjectResponse.error) {
            throw new Error(createProjectResponse.error);
          }

          projectId = createProjectResponse.project.project_id;

          setToastMessage("Project created successfully!");
          setToastType("success");
        } else {
          // Case where the project already exists and is passed as a prop
          projectId = project_id;
        }

        const formData = new FormData();
        formData.append("project_id", projectId);
        formData.append("user_id", userId);
        formData.append("content", prompt);
        formData.append("sentAt", new Date().toISOString());

        let key = null;
        let fileContent = null;

        if (currentFile) {
          formData.append("media", currentFile.file); // File object
          formData.append("file_name", currentFile.name);
          formData.append("mime_type", currentFile.type);

          const fileType = modalMapping[modalContent]?.messageType || "text";
          switch (fileType) {
            case "image":
              key = "image_bytes";
              break;
            case "audio":
              key = "audio_bytes";
              break;
            case "video":
              key = "video_bytes";
              break;
            default:
              key = "file_bytes";
          }

          const reader = new FileReader();
          reader.readAsDataURL(currentFile.file); // Convert to Base64
          reader.onloadend = async () => {
            fileContent = reader.result.split(",")[1]; // Extract the Base64 part

            // Append the file content to the formData
            formData.append(key, fileContent);
            // console.log(fileContent);
          };
        }
        formData.append("message_type", key || "text");

        const result = await apiService.storeUserMessage(formData);
        if (result.success) {
          setToastMessage(result.success);
          setToastType("success");

          let image_bytes = null;
          let audio_bytes = null;
          let video_bytes = null;

          if (key === "image_bytes") {
            image_bytes = fileContent;
          } else if (key === "audio_bytes") {
            audio_bytes = fileContent;
          } else if (key === "video_bytes") {
            video_bytes = fileContent;
          }

          const userInput = {
            image_bytes,
            audio_bytes,
            video_bytes,
          };

          formData.append("message_id", result.message_id);
          formData.append("userInput", userInput);
          formData.append("sentAt", new Date().toISOString());
          formData.append("prompt", prompt);

          // const newMessage = {
          //   id: result.message_id,
          //   sender: "User",
          //   prompt: textPrompt,
          //   text: textPrompt,
          //   image_bytes: fileContent,
          //   audio_bytes: null,
          //   video_bytes: null,
          //   avatar: "https://via.placeholder.com/50",
          //   file: {
          //     name: file.name,
          //     type: file.type,
          //   },
          //   sentAt: new Date().toISOString(),
          // };

          // console.log(newMessage);

          updateLocalState("newMessage", formData);
          // console.log(formData);

          if (createProject) {
            navigate(`/chatPage?projectId=${projectId}`);
          }
        } else {
          setToastMessage(result.error || "An error occurred");
          setToastType("error");
        }

        // else {
        //   const formData = new FormData();
        //   formData.append("project_id", projectId);
        //   formData.append("user_id", userId);
        //   formData.append("content", textPrompt);
        //   formData.append("message_type", "text");

        //   const result = await apiService.storeUserMessage(formData);
        //   if (result.success) {
        //     setToastMessage(result.success);
        //     setToastType("success");

        //     const newMessage = {
        //       id: result.message_id,
        //       sender: "User",
        //       prompt: textPrompt,
        //       text: textPrompt,
        //       image_bytes: null,
        //       audio_bytes: null,
        //       video_bytes: null,
        //       avatar: "https://via.placeholder.com/50",
        //       file: null,
        //       sentAt: new Date().toISOString(),
        //     };

        //     updateLocalState("newMessage", newMessage);

        //     if (createProject) {
        //       navigate(`/chatPage?projectId=${projectId}`);
        //     }
        //   } else {
        //     setToastMessage(result.error || "An error occurred");
        //     setToastType("error");
        //   }
        // }
      } catch (error) {
        console.error("Error storing user message:", error);
        setToastMessage("An error occurred while saving");
        setToastType("error");
      } finally {
        if (closeModal) {
          closeModal();
        }
        updateLocalState("textPrompt", "");
        updateLocalState("file", null);

        setTextPrompt("");
        setFile(null);
      }
    }
  };

  const modalMapping = {
    Audio: {
      title: "Attach Audio",
      fileTypes: [".mp3", ".aac", ".m4a", ".wav"],
      messageType: "audio",
    },
    Image: {
      title: "Attach Image",
      fileTypes: [".jpg", ".png"],
      messageType: "image",
    },
    Video: {
      title: "Attach Video",
      fileTypes: [".mp4", ".avi", ".mov"],
      messageType: "video",
    },
  };

  return (
    <div
      id={id}
      className={`self-center flex flex-col items-center justify-center gap-4 ${className} ${
        inputDisplay
          ? `rounded-full p-2`
          : inChatPage
          ? "rounded-lg p-2"
          : "rounded-lg"
      }`}
    >
      <div
        className={`self-stretch flex flex-col items-start justify-center bg-[#1d1b1d] border border-solid border-[#3c393c] w-full relative ${
          inputDisplay ? "rounded-full p-1 px-2" : "rounded-lg p-2"
        }`}
      >
        <div
          className={`self-stretch rounded-xl flex flex-row my-1 items-center justify-${
            inputDisplay ? "between" : "start"
          }`}
        >
          {inputDisplay && (
            <div className="flex items-center justify-center">
              <Dropdown
                options={[
                  { label: "Audio", icon: "MusicNote", id: "audio-option" },
                  { label: "Image", icon: "Picture", id: "image-option" },
                  { label: "Video", icon: "Filmstrip", id: "video-option" },
                ]}
                onOptionSelect={handleOptionSelect}
                className={`w-32 mb-1 font-medium text-sm text-[#878188] bg-[#100f10] border border-solid border-[#2c2a2c] rounded-md z-10 ${dropdownStyle}`}
                customInput
                inputDisplay={inputDisplay}
              />
            </div>
          )}
          <div
            className={`flex w-full ${
              !chatPage && !fromDiscoverPage && `px-2`
            }`}
          >
            {!chatPage && !fromDiscoverPage && (
              <div
                className="relative z-10"
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
                onClick={handleDiceClick}
                id="dice-icon"
              >
                <img
                  src="/Randomize.svg"
                  alt="Randomize"
                  className={`mt-[3px] transition duration-300 origin-center cursor-pointer`}
                  style={{
                    filter: `${
                      isHovered || diceIsClicked
                        ? `brightness(2) grayscale(0.8) contrast(1.2) saturate(1.2)`
                        : `brightness(0.8) grayscale(0.8) contrast(1.2) saturate(1.2)`
                    }`,
                    transform: `${
                      isHovered || diceIsClicked
                        ? "rotate(45deg)"
                        : "rotate(0deg)"
                    }`,
                    transition: "filter 0.3 ease, transform 0.3s ease",
                  }}
                />
              </div>
            )}
            <textarea
              id="text-input-field"
              type="text"
              className={`relative text-base font-extralight w-full bg-transparent px-2 ${
                !inputDisplay && fromDiscoverPage ? `mb-6` : !chatPage && `mb-4`
              } border-none focus:outline-none resize-none overflow-hidden scrollable-content`}
              value={textPrompt}
              placeholder={
                !inputDisplay && !fromDiscoverPage
                  ? `What music would you like to create?`
                  : fromDiscoverPage
                  ? `What would you like to customize`
                  : `Type your message here...`
              }
              onChange={handleChange}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  if (textPrompt?.trim()) {
                    e.preventDefault(); // Prevent default behavior (new line)
                    handleGenerate();
                  } else {
                    // Allow new line if there's no text
                    setInputDisplay(false);
                    e.stopPropagation();
                  }
                }
              }}
              rows={1}
              ref={textareaRef}
              style={{ maxHeight: `${maxHeight}px` }}
            />
          </div>

          {inputDisplay && (
            <div className="flex items-center justify-center">
              <button
                id="generate-button"
                disabled={
                  !((textPrompt && textPrompt.trim()) || file) ||
                  disableGenerate
                }
                title={disableGenerate ? "Too long" : ""}
                onClick={handleGenerate}
                className={`w-8 h-8 flex flex-row items-center justify-center py-0 px-2 rounded-full ${
                  (textPrompt && textPrompt.trim() && !disableGenerate) ||
                  (file && !disableGenerate)
                    ? "bg-gradient-to-r from-[#fc10f2] to-[#3078e4] hover:from-[#e509e4] hover:to-[#256bb8]"
                    : "text-[#878188] bg-[#4E4A4F]"
                }`}
              >
                <img
                  className="w-4 relative h-4 overflow-hidden shrink-0"
                  alt=""
                  src={
                    (textPrompt && textPrompt.trim() && !disableGenerate) ||
                    (file && !disableGenerate)
                      ? "/actiongenerate.svg"
                      : "/Generate.svg"
                  }
                />
              </button>
            </div>
          )}
        </div>
        {!inputDisplay && (
          <div className="self-stretch flex flex-row items-end justify-between text-sm text-color-grey-40">
            <div className="flex flex-row items-center justify-start relative">
              {!file && !isUploading && (
                <Dropdown
                  buttonLabel="Attach"
                  options={[
                    { label: "Audio", icon: "MusicNote", id: "audio-option" },
                    { label: "Image", icon: "Picture", id: "image-option" },
                    { label: "Video", icon: "Filmstrip", id: "video-option" },
                  ]}
                  onOptionSelect={handleOptionSelect}
                  className={`p-1 w-32 mb-1 font-medium text-sm text-[#878188] bg-[#100f10] border border-solid border-[#2c2a2c] rounded-md z-10 ${dropdownStyle}`}
                  customInput
                />
              )}
              {isUploading && (
                <div className="flex items-center">
                  <div className="w-3.5 h-3.5 border-2 border-t-2 border-grey-300 border-t-transparent rounded-full animate-spin"></div>
                  <span className="ml-2 text-gray-300">Uploading...</span>
                </div>
              )}
            </div>
            <button
              id="generate-button-2"
              title={disableGenerate ? "Too long" : ""}
              disabled={
                !((textPrompt && textPrompt.trim()) || file) || disableGenerate
              }
              onClick={handleGenerate}
              className={`w-8 h-8 flex flex-row items-center justify-center py-0 px-2 rounded-full ${
                (textPrompt && textPrompt.trim() && !disableGenerate) ||
                (file && !disableGenerate)
                  ? "bg-gradient-to-r from-[#fc10f2] to-[#3078e4] hover:from-[#e509e4] hover:to-[#256bb8]"
                  : "text-[#878188] bg-[#4E4A4F]"
              }`}
            >
              <img
                className="w-4 relative h-4 overflow-hidden shrink-0"
                alt=""
                src={
                  (textPrompt && textPrompt.trim() && !disableGenerate) ||
                  (file && !disableGenerate)
                    ? "/actiongenerate.svg"
                    : "/Generate.svg"
                }
              />
            </button>
          </div>
        )}

        {fromDiscoverPage && audioFile && (
          <div className=" absolute bottom-2 left-2 mt-2 flex items-center text-left">
            {imageSrc && (
              <img
                src={imageSrc}
                alt="Song"
                className="w-8 h-8 object-cover rounded-md mr-2"
              />
            )}
            <div className="flex-1">
              <p className="font-medium text-sm">{audioFile?.name}</p>
              {mood && (
                <p className="font-light text-xs text-gray-400">{mood}</p>
              )}
            </div>
          </div>
        )}

        {!fromDiscoverPage && file && !isUploading && (
          <div className="absolute bottom-2 left-2 flex items-center bg-transparent">
            <div className="flex items-center justify-center mr-1">
              <File className="w-5 h-5 text-white" />
            </div>
            <div className="flex flex-col justify-start">
              <span className="text-white text-sm truncate max-w-[200px]">
                {truncateFileName(file.name, 20)}
              </span>
            </div>
            <button
              onClick={handleFileRemove}
              className="ml-4 text-[#878188] hover:text-white text-lg"
            >
              <Xmark />
            </button>
          </div>
        )}
      </div>

      {modalContent && (
        <Modal closeModal={() => setModalContent(null)} showCloseButton={false}>
          <ModalContent
            closeModal={() => setModalContent(null)}
            title={modalMapping[modalContent].title}
            fileTypes={modalMapping[modalContent].fileTypes}
            handleFileChange={handleFileChange}
          />
        </Modal>
      )}
      {showToast && (
        <Toast
          type={toastType}
          message={toastMessage}
          onClose={() => setToastMessage("")}
        />
      )}
    </div>
  );
};

export default CustomInput;
