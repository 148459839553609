import React from "react";

const NoResultsFound = ({ onClearFilters }) => {
  return (
    <div className="flex flex-col items-center justify-center text-white">
      <h2 className="text-2xl font-semibold mb-2">No results found</h2>
      <p className="mb-4 text-sm font-extralight">
        Here are some tips while searching or filtering:
      </p>
      <ul className="list-disc mb-4 text-sm font-extralight">
        <li>Double-check for typos</li>
        <li>Clear your search filters</li>
        <li>Use fewer or different keywords</li>
      </ul>
      <button
        onClick={onClearFilters}
        className="px-3 h-8 bg-white hover:bg-[#E0DFE1] font-normal text-[#100f10] text-sm rounded-full"
      >
        Clear search and filters
      </button>
    </div>
  );
};

export default NoResultsFound;
