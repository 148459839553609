import React, { useState, useEffect, useCallback } from "react";
import { PlayFill, PauseFill, ThumbsUp, ThumbsUpFill } from "@gravity-ui/icons";
import { useAudioPlayer } from "../../contexts/AudioPlayerContext";
import Modal from "../modal/Modal"; // Import the Modal component
import CustomInput from "../custom_components/CustomInput"; // Import the CustomInput component
import apiService from "../../api/apiService";
import { getCookie } from "../../utils/cookieUtils";
import { useAuth } from "../../contexts/AuthContext";
import Waveform from "./Waveform";

const fetchBlobFromUrl = async (url) => {
  const response = await fetch(url);
  const blob = await response.blob();
  return blob;
};

const SongList = ({ songs }) => {
  const {
    isPlaying,
    playAudio,
    pauseAudio,
    resumeAudio,
    audioSrc: currentAudioSrc,
    currentTime,
    duration,
    seekAudio,
    setDiscoverId,
  } = useAudioPlayer();
  const [activeSong, setActiveSong] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedSong, setSelectedSong] = useState(null);
  const [likeStatus, setLikeStatus] = useState({});
  const [isLoadingAudio, setIsLoadingAudio] = useState(false);

  const { isLoggedIn } = useAuth();

  useEffect(() => {
    if (!currentAudioSrc) {
      setActiveSong(null);
    }
  }, [currentAudioSrc]);

  const handlePlayPause = useCallback(
    (audioSrc, name, description, imageSrc, duration) => {
      if (isPlaying && currentAudioSrc === audioSrc) {
        pauseAudio();
        setActiveSong(null);
      } else if (currentAudioSrc === audioSrc) {
        resumeAudio();
        setActiveSong(audioSrc);
      } else {
        playAudio(audioSrc, name, description, imageSrc, duration);
        setActiveSong(audioSrc);
      }
    },
    [isPlaying, currentAudioSrc, pauseAudio, resumeAudio, playAudio]
  );

  const handleOpenModal = async (song, e) => {
    e.stopPropagation();
    setSelectedSong(song);

    // Check if the song has an associated audio source (aiResponseId or discoverId)
    if (!song.id) {
      return;
    }

    setIsLoadingAudio(true);
    setIsModalOpen(true);

    // Determine which task to request based on aiResponseId or discoverId
    const taskName = "fetchDiscoverAudioFile";

    const requestPayload = { discoverId: song.id };

    // Fetch the audio file from the backend
    try {
      const response = await apiService.sendRequest(taskName, requestPayload);
      const { audioFile } = response;

      if (audioFile.data) {
        // Convert the audio data into a Blob
        const blob = new Blob([new Uint8Array(audioFile.data)], {
          type: "audio/wav", // Use the appropriate MIME type
        });

        // Convert Blob into a File object
        const file = new File([blob], `${song.name}.wav`, { type: blob.type });

        // Update the selected song with the audioFile
        setSelectedSong({ ...song, audioFile: file });
        setIsLoadingAudio(false);
      } else {
      }
    } catch (error) {
      console.error("Error fetching the audio file:", error);
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedSong(null);
  };

  useEffect(() => {
    // Initialize likeStatus state with the initial isLiked values of the songs
    const initialLikeStatus = songs.reduce((acc, song) => {
      acc[song.id] = song.isLiked ? "like" : "unlike";
      return acc;
    }, {});
    setLikeStatus(initialLikeStatus);
  }, [songs]);

  const handleLike = async (discoverId) => {
    const userId = getCookie("user_id");

    if (!userId || !discoverId) return;

    const payload = {
      discover_id: discoverId,
      user_id: userId,
      is_like: likeStatus[discoverId] !== "like", // Toggle like status
    };

    try {
      if (likeStatus[discoverId] === "like") {
        // If already liked, remove the like
        await apiService.sendRequest("removeDiscoverLike", payload);
        setLikeStatus((prevState) => ({
          ...prevState,
          [discoverId]: "unlike", // Update status to unlike
        }));
      } else {
        // Add the like
        await apiService.sendRequest("updateDiscoverLike", payload);
        setLikeStatus((prevState) => ({
          ...prevState,
          [discoverId]: "like", // Update status to like
        }));
      }
    } catch (error) {
      console.error("Error updating like:", error);
    }
  };

  return (
    <div>
      <ul className="list-none p-0 m-0">
        {songs.map((song, index) => (
          <li key={`${song.id}-${index}`} className="mb-2">
            <div
              onClick={() => {
                handlePlayPause(
                  song.audioSrc,
                  song.name,
                  song.mood,
                  song.image,
                  song.duration
                );
                setDiscoverId(song.id);
              }}
              className={`flex items-center p-1.5 pr-4 m-1.5 rounded-xl cursor-pointer group ${
                activeSong === song.audioSrc
                  ? "bg-gradient-to-r from-pink-500 via-purple-500 to-blue-500 text-white"
                  : "hover:bg-opacity-10 transition duration-500 hover:bg-white"
              }`}
              style={
                activeSong === song.audioSrc
                  ? {
                      background:
                        "linear-gradient(0deg, rgba(0, 0, 0, 0.72) 0%, rgba(0, 0, 0, 0.72) 100%), linear-gradient(135deg, #FC10F2 0%, #3078E4 100%)",
                    }
                  : {}
              }
            >
              <div className="flex items-center relative overflow-hidden mr-4">
                <img
                  src={song.image}
                  alt={song.name}
                  className="w-12 h-12 object-cover rounded-lg"
                />
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    handlePlayPause(
                      song.audioSrc,
                      song.name,
                      song.mood,
                      song.image,
                      song.duration
                    );
                    setDiscoverId(song.id);
                  }}
                  className={`absolute inset-0 flex items-center justify-center text-white transition-transform duration-500 transform group-hover:opacity-100 ${
                    isPlaying && currentAudioSrc === song.audioSrc
                      ? "translate-y-0"
                      : "translate-y-[70%] group-hover:translate-y-0"
                  }`}
                >
                  {isPlaying && currentAudioSrc === song.audioSrc ? (
                    <PauseFill className="w-4 h-4" />
                  ) : (
                    <PlayFill className="w-4 h-4" />
                  )}
                </button>
              </div>
              <div className="flex-1 min-w-0">
                <p className="font-medium text-base truncate">{song.name}</p>
                <p className="font-light text-sm text-gray-400 truncate">
                  {song.mood}
                </p>
              </div>

              {/* Right-aligned items */}
              <div className="flex items-center ml-auto gap-20">
                <span className="hidden md:block font-light text-sm text-white">
                  {song.duration}
                </span>

                {/* Waveform Audio Player */}
                <div className="hidden md:flex flex-grow">
                  <Waveform
                    waveform={song.waveformData.waveform}
                    audioSrc={song.audioSrc}
                    currentTime={
                      currentAudioSrc === song.audioSrc ? currentTime : 0
                    }
                    duration={currentAudioSrc === song.audioSrc ? duration : 0}
                    isPlaying={isPlaying && currentAudioSrc === song.audioSrc}
                    onSeek={(newTime) => seekAudio(newTime)}
                    isActive={currentAudioSrc === song.audioSrc}
                  />
                </div>

                <div className="flex items-center gap-6">
                  <button
                    className="flex flex-row text-white hover:text-gray-300 "
                    onClick={(e) => handleOpenModal(song, e)}
                  >
                    <img src="/Generate.svg" />
                  </button>

                  {isLoggedIn && ( // Only show the like button if the user is logged in
                    <button
                      className="flex text-[#878188] hover:text-gray-300"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleLike(song.id);
                      }}
                    >
                      {likeStatus[song.id] === "like" ? (
                        <ThumbsUpFill />
                      ) : (
                        <ThumbsUp />
                      )}
                    </button>
                  )}
                </div>
              </div>
            </div>
          </li>
        ))}
      </ul>

      {isModalOpen && selectedSong && (
        <Modal closeModal={handleCloseModal} showCloseButton={false}>
          <CustomInput
            audioFile={selectedSong.audioFile}
            imageSrc={selectedSong.image}
            mood={selectedSong.mood}
            fromDiscoverPage={true}
            className="w-full md:w-[40rem]"
            requestData={{ createProject: true }}
            chatPage={false}
            isLoadingAudio={isLoadingAudio}
          />
        </Modal>
      )}
    </div>
  );
};

export default SongList;
